import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    loginError: null,
    userData: null,
    userProfile: null,
    pageId: "",
    pages: [],
    notificationBadge: false,
    messageBadge: false,
    isSystemUser: false,
    systemUserData: null,
};

const slice = createSlice({
    name: "login",
    initialState,
    reducers: {
        requestLogin(state, action) {
            state.isLoading = action.payload;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.loginError = action.payload;
        },
        loginSuccess(state, action) {
            state.isLoading = false;
            state.userData = action.payload;
        },
        setUserProfile(state, action) {
            state.isLoading = false;
            state.userProfile = action.payload;
        },
        setNotificationBadge(state, action) {
            state.notificationBadge = action.payload;
        },
        setMessageBadge(state, action) {
            state.messageBadge = action.payload;
        },
        setNotificationData(state, action) {
            state.notificationsdata = action.payload;
        },
        setSystemUserEnabled(state, action) {
            state.isSystemUser = action.payload;
        },
        setSystemUserData(state, action) {
            state.systemUserData = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;
//Actions
export const {
    requestLogin,
    hasError,
    loginSuccess,
    setUserProfile,
    setNotificationBadge,
    setNotificationData,
    setMessageBadge,
    setSystemUserEnabled,
    setSystemUserData
} = slice.actions;
