import React, { useEffect, useState } from "react";
import XHR from "../../utills/XHR";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationData } from "../../store/slices/notificationSlice";
import { setNotificationBadge } from "../../store/slices/loginSlice";
import NotificationItem from "../../components/header/NotificationItem";
import { BellOff, Check, Ellipsis, MoveLeft } from "lucide-react";
import InfiniteScrollComp from "react-infinite-scroll-component";
import toast from "react-hot-toast";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { ListItemIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { updateUniversalStyles } from "../../store/slices/bodyStyling";

const Notifications = ({ darkMode }) => {
    const hostname = "dashboard.redefine.church";
    const user = useSelector((state) => state.login.userData);
    const [notificationsData, setNotifications] = useState({
        notifications: [],
        notifications_last_page: 1,
        notifications_current_page: 1,
        all_read_check: 1,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [skeletonLoader, setSkeletonLoader] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = (e) => {
        setAnchorEl(null);
    };

    const [loader, setLoader] = useState({
        accept: false,
        decline: false,
        notificationId: null,
    });

    useEffect(() => {
        getNotifications(1);
    }, []);

    const getNotifications = (page) => {
        if (skeletonLoader) return;
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "GET",
        };
        if (page == 1) {
            setSkeletonLoader(true);
        }

        XHR(
            `${process.env.REACT_APP_BASE_URL}/api/v1/getNotifications?user_id=${user?.id}&&page=${page}`,
            options
        )
            .then((resp) => {
                if (resp?.data?.success) {
                    const {
                        notifications_current_page = 1,
                        notifications_last_page = 1,
                        notifications = [],
                        all_read_check = 1,
                    } = resp?.data;

                    if (notifications_current_page > 1) {
                        setNotifications((prev) => {
                            const newData = [
                                ...prev.notifications,
                                ...notifications,
                            ];

                            const removedDuplicates = newData.filter(
                                (item, index, self) =>
                                    index ===
                                    self.findIndex((t) => t.id == item.id) // Use the id or another unique property
                            );
                            const data = {
                                notifications: removedDuplicates,
                                notifications_current_page,
                                notifications_last_page,
                                all_read_check,
                            };
                            dispatch(setNotificationData(data));
                            return data;
                        });
                    } else {
                        setNotifications({
                            notifications,
                            notifications_current_page,
                            notifications_last_page,
                            all_read_check,
                        });
                    }
                    setTimeout(() => {
                        if (all_read_check == 1) {
                            dispatch(setNotificationBadge(true));
                        } else {
                            dispatch(setNotificationBadge(false));
                        }
                    }, 500);
                    dispatch(
                        updateUniversalStyles(resp?.data?.universal_styles)
                    );
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setSkeletonLoader(false);
            });
    };

    const handleRequest = (id, action, notificationId) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: {
                connection_id: id,
            },
        };
        setLoader({
            decline: action === "decline" ? true : false,
            accept: action === "accept" ? true : false,
            notificationId,
        });
        XHR(
            `${process.env.REACT_APP_BASE_URL}/api/v1/${
                action === "accept"
                    ? "acceptConnectionRequest"
                    : "rejectConnectionRequest"
            }`,
            options
        )
            .then((response) => {
                if (response.data.success) {
                    toast.success(
                        `Request ${
                            action === "accept" ? "Accepted" : "Declined"
                        }`,
                        { duration: 3000 }
                    );
                    setNotifications((prev) => {
                        const deepCopy = JSON.parse(
                            JSON.stringify(prev.notifications)
                        );
                        const notiIndex = deepCopy?.findIndex(
                            (item) => item.id == notificationId
                        );
                        if (notiIndex !== -1) {
                            deepCopy[notiIndex].status =
                                action === "accept" ? 1 : 2;
                            const temp = {
                                ...prev,
                                notifications: deepCopy,
                            };
                            dispatch(setNotificationData(temp));
                            return temp;
                        }
                        return prev;
                    });
                }
                console.log("RESP>", response);
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            })
            .finally(() => {
                setLoader({
                    decline: false,
                    accept: false,
                    notificationId: null,
                });
            });
    };

    const handleEvent = (invitation_id, status, notificationId) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: { invitation_id, status },
        };
        setLoader({
            decline: status == 2 ? true : false,
            accept: status == 1 ? true : false,
            notificationId,
        });
        XHR(
            `${process.env.REACT_APP_BASE_URL}/user-change-event-status`,
            options
        )
            .then((resp) => {
                if (resp.data.success) {
                    toast.success(
                        status == 1
                            ? "Thanks for accepting invitation."
                            : "You have declined invitation request."
                    );
                    setNotifications((prev) => {
                        const deepCopy = JSON.parse(
                            JSON.stringify(prev.notifications)
                        );
                        const notiIndex = deepCopy?.findIndex(
                            (item) => item.id == notificationId
                        );
                        if (notiIndex !== -1) {
                            deepCopy[notiIndex] = {
                                ...deepCopy[notiIndex],
                                event_detail: {
                                    ...deepCopy[notiIndex]?.event_detail,
                                    invitation_status: status,
                                },
                            };

                            const temp = {
                                ...prev,
                                notifications: deepCopy,
                            };
                            dispatch(setNotificationData(temp));
                            return temp;
                        }
                        return prev;
                    });
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setLoader({
                    decline: false,
                    accept: false,
                    notificationId: null,
                });
            });
    };

    const changeNotificationStatus = (noti_Ids, check) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: {
                notification_ids: noti_Ids,
            },
        };
        XHR(
            `${process.env.REACT_APP_BASE_URL}/api/v1/changeNotificationStatus`,
            options
        )
            .then((response) => {
                if (check === "all") {
                    toast.success("Notifications marked as read", {
                        duration: 3000,
                    });
                }

                setNotifications((prev) => {
                    const updatedData = updateNotifications(
                        prev.notifications,
                        noti_Ids
                    );
                    const badgeToShow = updatedData.filter(
                        (item) => item.is_read == 0
                    );
                    setTimeout(() => {
                        if (badgeToShow.length > 0) {
                            dispatch(setNotificationBadge(true));
                        } else {
                            dispatch(setNotificationBadge(false));
                        }
                    }, 500);
                    const temp = {
                        ...prev,
                        notifications: updatedData,
                    };
                    dispatch(setNotificationData(temp));
                    return temp;
                });
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            });
    };

    const updateNotifications = (notifications, idsToUpdate) => {
        return notifications.map((notification) => {
            if (idsToUpdate.includes(notification.id)) {
                return { ...notification, is_read: 1 };
            }
            return notification;
        });
    };

    const SkeletonComp = () => {
        return (
            <div
                className="d-flex gap-3 align-items-center"
                style={{
                    overflow: "hidden",
                    minHeight: 56,
                }}
            >
                <Skeleton baseColor="#716e6e" circle height={50} width={50} />
                <p
                    style={{
                        fontSize: 12,
                        fontWeight: "500",
                        marginBottom: 0,
                        width: "90%",
                    }}
                >
                    <Skeleton baseColor="#716e6e" height={8} />
                    <Skeleton baseColor="#716e6e" height={8} />
                </p>
            </div>
        );
    };

    const onEndNotification = () => {
        if (
            notificationsData.notifications_current_page <
                notificationsData.notifications_last_page &&
            !skeletonLoader
        ) {
            const nextPage = notificationsData.notifications_current_page + 1;
            getNotifications(nextPage);
        }
    };

    const markAllAsRead = () => {
        const ids = notificationsData.notifications
            .filter((item) => item.is_read == 0)
            .map((item) => item.id);
        if (ids.length > 0) {
            changeNotificationStatus(ids, "all");
        }
    };

    return (
        <div className="container" style={{ height: "95vh" }}>
            <div className="row">
                <div className="col-md-12 contactcenterBoxwrap">
                    <div className="d-flex col-md-12 post-detail-wrapper noti-wrapper">
                        <div
                            onClick={() => {
                                navigate(-1);
                            }}
                            className="col-md-2 noti-back "
                            style={{ cursor: "pointer" }}
                        >
                            <MoveLeft
                                size={30}
                                color={darkMode ? "#ffffff" : "#000"}
                                fill="#ffffff"
                            />
                        </div>
                        <div
                            className="d-flex col-md-8 flex-column"
                            style={{
                                borderRadius: 10,
                                backgroundColor: darkMode ? "#171717" : "#fff",
                                padding: 16,
                            }}
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                <h3
                                    style={{
                                        color: darkMode ? "#fff" : "#000",
                                    }}
                                >
                                    Notifications
                                </h3>
                                <div
                                    onClick={handleMenuClick}
                                    className="all-read-ellipsis"
                                >
                                    <Ellipsis size={24} color="grey" />
                                </div>
                            </div>
                            {/* List */}
                            <div
                                className="mt-3 gap-2 notifications-cont"
                                id="scrollableDiv"
                            >
                                <InfiniteScrollComp
                                    dataLength={
                                        notificationsData.notifications.length
                                    }
                                    next={onEndNotification}
                                    hasMore={
                                        notificationsData.notifications_current_page <
                                        notificationsData.notifications_last_page
                                    }
                                    className="d-flex flex-column gap-2"
                                    loader={<SkeletonComp />}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {skeletonLoader ? (
                                        Array(10)
                                            .fill(null)
                                            .map((_, i) => {
                                                return <SkeletonComp key={i} />;
                                            })
                                    ) : notificationsData?.notifications &&
                                      notificationsData?.notifications
                                          ?.length == 0 ? (
                                        <div className="d-flex  flex-column align-items-center justify-content-center gap-2 my-2">
                                            <BellOff
                                                size={80}
                                                color="#b0b3b8"
                                            />
                                            <h5
                                                style={{
                                                    color: "#b0b3b8",
                                                }}
                                            >
                                                You have no notifications.
                                            </h5>
                                        </div>
                                    ) : (
                                        notificationsData?.notifications.map(
                                            (item, index) => {
                                                return item?.type == 3 &&
                                                    item?.event_detail ==
                                                        null ? null : (
                                                    <NotificationItem
                                                        key={index}
                                                        item={item}
                                                        handleEvent={
                                                            handleEvent
                                                        }
                                                        handleRequest={
                                                            handleRequest
                                                        }
                                                        loader={loader}
                                                        changeNotificationStatus={
                                                            changeNotificationStatus
                                                        }
                                                        darkMode={darkMode}
                                                    />
                                                );
                                            }
                                        )
                                    )}
                                </InfiniteScrollComp>
                            </div>
                            {/* Material UI Menu */}
                            <Menu
                                sx={{
                                    "& .MuiPaper-root": {
                                        backgroundColor: "#393737",
                                        color: "#fff",
                                        borderRadius: "8px",
                                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                                        border: "1 px solid #e0e0e0",
                                    },
                                }}
                                disableScrollLock={true}
                                anchorEl={anchorEl}
                                open={isMenuOpen}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                PaperProps={{
                                    elevation: 3,
                                    sx: {
                                        borderRadius: "8px",
                                        minWidth: "160px",
                                        marginTop: "4px",
                                    },
                                }}
                            >
                                <MenuItem
                                    onClick={(e) => {
                                        markAllAsRead();
                                        handleMenuClose(e);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Check size={20} color="#fff" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Mark all as read
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div
        //     className="d-flex container-fluid justify-content-center"
        //     style={{ height: "100vh" }}
        // >
        //     <div className="d-flex col-md-10 justify-content-center my-3">
        //         <div
        //             onClick={() => {
        //                 navigate(-1);
        //             }}
        //             className="d-flex col-md-2 justify-content-center"
        //             style={{ cursor: "pointer" }}
        //         >
        //             <MoveLeft
        //                 // style={{ marginTop: 50 }}
        //                 size={30}
        //                 color="#ffffff"
        //                 fill="#ffffff"
        //             />
        //         </div>
        //         <div
        //             className="d-flex col-md-8 flex-column"
        //             style={{
        //                 borderRadius: 10,
        //                 backgroundColor: "#171717",
        //                 padding: 16,
        //             }}
        //         >
        //             <div className="d-flex justify-content-between align-items-center">
        //                 <h3
        //                     style={{
        //                         color: "#fff",
        //                     }}
        //                 >
        //                     Notifications
        //                 </h3>
        //                 <div
        //                     onClick={handleMenuClick}
        //                     className="all-read-ellipsis"
        //                 >
        //                     <Ellipsis size={24} color="grey" />
        //                 </div>
        //             </div>
        //             {/* List */}
        //             <div
        //                 className="mt-3 gap-2 notifications-cont"
        //                 id="scrollableDiv"
        //             >
        //                 <InfiniteScrollComp
        //                     dataLength={notificationsData.notifications.length}
        //                     next={onEndNotification}
        //                     hasMore={
        //                         notificationsData.notifications_current_page <
        //                         notificationsData.notifications_last_page
        //                     }
        //                     className="d-flex flex-column gap-2"
        //                     loader={<SkeletonComp />}
        //                     scrollableTarget="scrollableDiv"
        //                 >
        //                     {skeletonLoader ? (
        //                         Array(10)
        //                             .fill(null)
        //                             .map((_, i) => {
        //                                 return <SkeletonComp key={i} />;
        //                             })
        //                     ) : notificationsData?.notifications &&
        //                       notificationsData?.notifications?.length == 0 ? (
        //                         <div className="d-flex  flex-column align-items-center justify-content-center gap-2 my-2">
        //                             <BellOff size={80} color="#b0b3b8" />
        //                             <h5
        //                                 style={{
        //                                     color: "#b0b3b8",
        //                                 }}
        //                             >
        //                                 You have no notifications.
        //                             </h5>
        //                         </div>
        //                     ) : (
        //                         notificationsData?.notifications.map(
        //                             (item, index) => {
        //                                 return (
        //                                     <NotificationItem
        //                                         key={index}
        //                                         item={item}
        //                                         handleEvent={handleEvent}
        //                                         handleRequest={handleRequest}
        //                                         loader={loader}
        //                                         changeNotificationStatus={
        //                                             changeNotificationStatus
        //                                         }
        //                                     />
        //                                 );
        //                             }
        //                         )
        //                     )}
        //                 </InfiniteScrollComp>
        //             </div>
        //             {/* Material UI Menu */}
        //             <Menu
        //                 sx={{
        //                     "& .MuiPaper-root": {
        //                         backgroundColor: "#393737",
        //                         color: "#fff",
        //                         borderRadius: "8px",
        //                         boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        //                         border: "1 px solid #e0e0e0",
        //                     },
        //                 }}
        //                 disableScrollLock={true}
        //                 anchorEl={anchorEl}
        //                 open={isMenuOpen}
        //                 onClose={handleMenuClose}
        //                 anchorOrigin={{
        //                     vertical: "bottom",
        //                     horizontal: "right",
        //                 }}
        //                 transformOrigin={{
        //                     vertical: "top",
        //                     horizontal: "right",
        //                 }}
        //                 PaperProps={{
        //                     elevation: 3,
        //                     sx: {
        //                         borderRadius: "8px",
        //                         minWidth: "160px",
        //                         marginTop: "4px",
        //                     },
        //                 }}
        //             >
        //                 <MenuItem
        //                     onClick={(e) => {
        //                         markAllAsRead();
        //                         handleMenuClose(e);
        //                     }}
        //                 >
        //                     <ListItemIcon>
        //                         <Check size={20} color="#fff" />
        //                     </ListItemIcon>
        //                     <ListItemText>Mark all as read</ListItemText>
        //                 </MenuItem>
        //             </Menu>
        //         </div>
        //     </div>
        // </div>
    );
};

export default Notifications;
