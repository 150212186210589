import React, { useEffect, useState } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { Send } from "lucide-react";

const TeamChatWidget = ({ widgetId, itemStyles }) => {
    const universalStyles = useSelector(
        (state) => state.bodyStyling.universalStyles
    );

    const [border, setBorder] = useState("");
    const [defaultCorners, setDefaultCorners] = useState("true");

    useEffect(() => {
        if (itemStyles) {
            setDefaultCorners(itemStyles.defaultCorners);
            setBorder(itemStyles.border);
        }
    }, [itemStyles]);

    useEffect(() => {
        if (universalStyles.corners && itemStyles.defaultCorners == "true") {
            setBorder(universalStyles.corners);
        }
    }, [universalStyles.corners, itemStyles]);

    const messages = [
        {
            id: 1,
            name: "Sarah Collin",
            msg: "Hello guys, i am sending the link",
            time: "5 min",
        },
        {
            id: 2,
            name: "Jane doe",
            msg: "I'm good, what about u?",
            time: "1 hour",
        },
        { id: 3, name: "Jack Garet", msg: "ola, como estas?", time: "2 days" },
        { id: 4, name: "Admin", msg: "Hi, how are you?", time: "5 min" },
        {
            id: 5,
            name: "Jane doe",
            msg: "I'm good, what about u?",
            time: "1 hour",
        },
        {
            id: 6,
            name: "Jack Garet",
            msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias, tempore? Laudantium libero, eligendi tenetur architecto quod ipsum perspiciatis at suscipit nemo, officiis perferendis doloribus nihil cum neque iste enim. Soluta?",
            time: "2 days",
        },
        { id: 11, name: "Admin", msg: "Hi, how are you?", time: "5 min" },
        {
            id: 7,
            name: "Jane doe",
            msg: "I'm good, what about u?",
            time: "1 hour",
        },
        { id: 8, name: "Jack Garet", msg: "ola, como estas?", time: "2 days" },
    ];

    return (
        <div
            className={`chatBox `}
            style={{
                borderRadius:
                    border === "round" ? 20 : border === "sharp" ? 0 : null,
                padding: 10,
            }}
        >
            <div style={{ overflow: "auto" }}>
                {messages.map((item) => {
                    return (
                        <div className="mb-2">
                            <div
                                className="d-flex justify-content-between"
                                style={{ width: "100%" }}
                            >
                                <p
                                    style={{
                                        color: "#3478ff",
                                        margin: 0,
                                        fontSize: 14,
                                    }}
                                >
                                    {item.name}
                                </p>
                                <p
                                    style={{
                                        color: "grey",
                                        margin: 0,
                                        fontSize: 12,
                                    }}
                                >
                                    {item.time}
                                </p>
                            </div>
                            <div
                                className=""
                                style={{
                                    backgroundColor: "#272727",
                                    padding: 6,
                                    borderRadius: 10,
                                    width: "fit-content",
                                    marginTop: 4,
                                }}
                            >
                                <p
                                    style={{
                                        color: "#ffffff",
                                        margin: 0,
                                        fontSize: 14,
                                    }}
                                >
                                    {item.msg}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div
                className="d-flex align-items-center justify-content-between"
                style={{ backgroundColor: "#212121", padding: 10 }}
            >
                <p style={{ fontSize: 14, color: "#ffffff", margin: 0 }}>
                    Type something
                </p>
                <Send color="#3578ff" />
            </div>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    borderRadius:
                        border === "round" ? 20 : border === "sharp" ? 0 : null,
                }}
            >
                <p style={{ fontWeight: "bold" }}>Comments coming soon!</p>
            </div>
        </div>
    );
};

export default TeamChatWidget;
