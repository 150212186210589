import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const DarkModeContext = createContext();

function DarkModeProvider(props) {
    const [darkMode, setDarkMode] = useState(true);
    const [borderCorners, setCorners] = useState("round");
    const themeMode = useSelector(
        (state) => state.bodyStyling.styling.universal_styles
    );
    const corners = useSelector(
        (state) => state.bodyStyling.styling.universal_styles
    );
    const universalStyles = useSelector(
        (state) => state.bodyStyling.universalStyles
    );

    useEffect(() => {
        if (themeMode && themeMode.theme) {
            if (themeMode.theme === "light") {
                setDarkMode(false);
            } else {
                setDarkMode(true);
            }
        }
        if (corners && corners.corners) {
            setCorners(corners.corners);
        }
        if (universalStyles && universalStyles.theme) {
            if (universalStyles.theme === "light") {
                setDarkMode(false);
            } else {
                setDarkMode(true);
            }
        }
    }, [themeMode, corners, universalStyles]);

    const handleLightMode = () => setDarkMode(false);

    const handleDarkMode = () => setDarkMode(true);

    return (
        <div>
            <DarkModeContext.Provider
                value={{
                    darkMode,
                    handleLightMode,
                    handleDarkMode,
                    borderCorners,
                }}
            >
                {props.children}
            </DarkModeContext.Provider>
        </div>
    );
}

export { DarkModeContext, DarkModeProvider };
