import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    Send,
    Search,
    Menu as MenuIcon,
    ArrowLeft,
    UsersRound,
    X,
    Paperclip,
    MessageSquarePlus,
} from "lucide-react";
import XHR from "../../utills/XHR";
import { useDispatch, useSelector } from "react-redux";
import { removeStartingSlash } from "../../utills/RemoveSlash.js";
import placeholder from "../../assets/placeholder.png";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";
import { Circles } from "react-loader-spinner";
import InfiniteScrollComp from "react-infinite-scroll-component";
import { database } from "../../firebase/firebaseConfig.js";
import { ref, onChildAdded, off, onValue } from "firebase/database";
import ChatBubble from "./ChatBubble.js";
import generateItems from "../../utills/GenerateItems.js";
import _ from "lodash"; // Import lodash
import ConversationComp from "./ConversationComp.js";
import ChatDeleteModal from "../../components/Modal/ChatDeleteModal.js";
import { toast } from "react-hot-toast";
import ChatOverayLoader from "../../components/Loader/ChatOverlayLoader.js";
import { updateUniversalStyles } from "../../store/slices/bodyStyling.js";

const ChatInterface = ({ darkMode }) => {
    const [message, setMessage] = useState("");

    const [showConversations, setShowConversations] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const user = useSelector((state) => state.login.userData);
    const hostname = process.env.REACT_APP_BASE_URL;
    const dispatch = useDispatch();

    const [editMsg, setEditMsg] = useState({ msg: "", id: "" });
    const [isHovered, setIsHovered] = useState(null);

    const [visibleReactions, setVisibleReactions] = useState(false);
    const [selectedImg, setSelectedImg] = useState(null);

    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const [filteredConnections, setFilteredConnections] = useState([]);
    const [connectionSearchQuery, setConnectionSearchQuery] = useState("");

    const [showConnections, setShowConnections] = useState(false);

    const [deleteModal, setShowDeleteModal] = useState(null);

    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        // Trigger the file input
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        // Handle the selected file
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    console.log("IMG>", event.target.result);
                    setSelectedImg(event.target.result);
                };
                reader.readAsDataURL(selectedFile);
            } else {
                alert("Please select a valid image file.");
            }
        }
        // Reset the file input's value to allow selecting the same file again
        e.target.value = null;
    };

    const [connectionList, setConnectionList] = useState([]);

    const [loader, setLoader] = useState({
        convo: false,
        chat: false,
        overlay: false,
        editMsg: false,
        connections: false,
        deleteCovo: false,
    });

    const [chat, setChat] = useState({
        name: "",
        chat: [],
        image: null,
        type: "",
        chat_current_page: 1,
        chat_last_page: 1,
    });
    const [conversations, setConverstaions] = useState({
        conversations: [],
        conversations_current_page: 1,
        conversations_last_page: 1,
        archives: [],
    });

    const activeChatRef = useRef(null);

    useEffect(() => {
        activeChatRef.current = chat;
    }, [chat]);

    // Update screen size state on resize
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
            setShowConversations(false);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        getChatList(1);
    }, []);

    // Firebase Listeners
    let newItems = false;
    useEffect(() => {
        // New Message listener
        const newMessageRef = ref(database, "GlobalChatMessageSent");
        onChildAdded(newMessageRef, handleNewMessages);
        // Message count listener
        const messageCountRef = ref(database, "GlobalChatMessageCount");
        onChildAdded(messageCountRef, handleMessageCount);
        // Delete Msg listener
        const messageDelRef = ref(database, "GlobalChatMessageDelete");
        onChildAdded(messageDelRef, handleMessageDel);
        // Edit Message listener
        const messageEditRef = ref(database, "GlobalChatMessageEdit");
        onChildAdded(messageEditRef, handleEditListener);
        // Rreaction Message listener
        const reactMessageRef = ref(database, "GlobalChatMessageResponse");
        onChildAdded(reactMessageRef, handleReactionListener);

        // handle initial items
        onValue(ref(database), handleInitialItems);
    }, []);

    const handleInitialItems = () => {
        newItems = true;
    };

    // NEW MESSAGES
    const handleNewMessages = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            // console.log("MSG>>", snapshot.val());
            const e = snapshot.val();

            setChat((prev) => {
                const chat = prev.chat; // Use prev.chat for all manipulations

                if (e?.message && e.message?.sender_user_id == user?.id) {
                    const updatedChat = chat.map((item) => {
                        if (item?.msg_id == e?.message?.msg_id) {
                            return {
                                ...e.message,
                                deleted_at: null,
                                reactions: [],
                                status: "sent",
                            };
                        } else {
                            return item;
                        }
                    });
                    // Adding conversation id for newly created chat
                    if (
                        !prev?.conversation_id &&
                        prev?.chat_user_id == e?.receiver_id
                    ) {
                        setConverstaions((prev) => {
                            const tempConvos = [...prev.conversations];
                            const convoItemIndex = tempConvos.findIndex(
                                (item) => item?.chat_user_id == e?.receiver_id
                            );

                            if (convoItemIndex !== -1) {
                                tempConvos[convoItemIndex] = {
                                    ...tempConvos[convoItemIndex],
                                    conversation_id: e?.conversation_id,
                                };
                                return {
                                    ...prev,
                                    conversations: tempConvos,
                                };
                            }
                            return prev;
                        });

                        return {
                            ...prev,
                            chat: updatedChat,
                            conversation_id: e?.conversation_id,
                        };
                    }
                    //

                    return {
                        ...prev,
                        chat: updatedChat,
                    };
                } else if (
                    (prev.type === "individual" &&
                        prev?.conversation_id &&
                        prev?.chat_user_id == e.message?.sender_user_id &&
                        e?.receiver_id == user?.id &&
                        e?.conversation_id == prev?.conversation_id) ||
                    (prev?.chat_user_id == e.message?.sender_user_id &&
                        e?.receiver_id == user?.id)
                ) {
                    const newMessage = {
                        ...e.message,
                        deleted_at: null,
                        reactions: [],
                    };
                    const temp = {
                        ...prev,
                        chat: [newMessage, ...chat],
                    };
                    return temp;
                }
                // For group
                else if (e?.conversation_id == prev?.conversation_id) {
                    const newMessage = {
                        ...e.message,
                        deleted_at: null,
                        reactions: [],
                    };
                    const temp = {
                        ...prev,
                        chat: [newMessage, ...chat],
                    };
                    return temp;
                }
                return prev;
            });

            setTimeout(() => {
                scrollToBottom();
            }, 200);
        }
    };
    // MESSAGE COUNT
    const handleMessageCount = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            // console.log("COUNT>>", snapshot.val());
            const e = snapshot.val();

            setConverstaions((prev) => {
                const conversationIndex = prev.conversations.findIndex(
                    (x) => x.conversation_id == e.conversation_id
                );
                if (conversationIndex !== -1) {
                    const tempArray = [...prev.conversations];
                    tempArray[conversationIndex] = {
                        ...tempArray[conversationIndex],
                        last_message: e.conversation?.last_message,
                        read_count:
                            activeChatRef?.current?.conversation_id ==
                            e.conversation_id
                                ? 0
                                : e?.conversation?.last_message
                                      ?.sender_user_id == user?.id
                                ? 0
                                : e.conversation.read_count,
                        latest_message_at:
                            e?.conversation?.last_message?.created_at,
                    };
                    tempArray.sort((a, b) => {
                        const dateA = new Date(
                            a?.latest_message_at || a?.conversation_created_at
                        );
                        const dateB = new Date(
                            b?.latest_message_at || b?.conversation_created_at
                        );
                        return dateB - dateA;
                    });
                    return {
                        ...prev,
                        conversations: tempArray,
                    };
                }
                return prev;
            });
        }
    };
    // MESSAGE DELETE
    const handleMessageDel = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            console.log("COUNT>>", snapshot.val());
            const deletedMessage = snapshot.val();
            if (deletedMessage?.message_id) {
                setChat((prev) => {
                    const temp = prev.chat.map((msg) =>
                        msg.id == deletedMessage.message_id
                            ? {
                                  ...msg,
                                  deleted_at: new Date().toISOString(),
                                  attachment_file: null,
                              }
                            : msg
                    );
                    return {
                        ...prev,
                        chat: temp,
                    };
                });
            }
        }
    };
    // Edit Message Handler
    const handleEditListener = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            const e = snapshot.val();
            console.log("EDIT MSG>>", e);
            setChat((prev) => {
                const messageIndex = prev.chat?.findIndex(
                    (x) => x.id == e.message.id
                );
                if (messageIndex !== -1) {
                    const tempArray = [...prev.chat];
                    tempArray[messageIndex] = {
                        ...e.message,
                        reactions: tempArray[messageIndex]?.reactions
                            ? [...tempArray[messageIndex].reactions]
                            : [],
                        deleted_at: null,
                        msg_id: tempArray[messageIndex]?.msg_id,
                    };
                    console.log("MSG>", tempArray[messageIndex]);

                    return {
                        ...prev,
                        chat: tempArray,
                    };
                }
                return prev;
            });
        }
    };

    // Listener for Reaction chat messages
    const handleReactionListener = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            const e = snapshot.val();
            console.log("Reaction MSG>", e);
            if (e?.message_id && e?.message_reactions) {
                setChat((prev) => {
                    const messageIndex = prev?.chat.findIndex(
                        (x) => x.id == e.message_id
                    );
                    const tempArray = [...prev.chat];
                    tempArray[messageIndex] = {
                        ...tempArray[messageIndex],
                        reactions: [...e.message_reactions],
                        deleted_at: null,
                    };
                    return { ...prev, chat: tempArray };
                });
            } else {
                setChat((prev) => {
                    const messageIndex = prev?.chat?.findIndex(
                        (x) => x.id == e.message_id
                    );
                    const tempArray = [...prev.chat];
                    tempArray[messageIndex] = {
                        ...tempArray[messageIndex],
                        reactions: [],
                        deleted_at: null,
                    };
                    return { ...prev, chat: tempArray };
                });
            }
        }
    };

    const getChatList = (page) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "GET",
        };
        setLoader((prev) => {
            return {
                ...prev,
                convo: true,
            };
        });
        XHR(`${hostname}/api/v1/getInbox?page=${page}`, options)
            .then((resp) => {
                if (resp?.data) {
                    // console.log(resp.data);
                    const {
                        conversations,
                        conversations_current_page,
                        conversations_last_page,
                    } = resp.data;
                    setConverstaions((prev) => {
                        return {
                            ...prev,
                            conversations: [
                                ...prev.conversations,
                                ...conversations,
                            ],
                            conversations_current_page,
                            conversations_last_page,
                        };
                    });
                    dispatch(
                        updateUniversalStyles(resp?.data?.universal_styles)
                    );
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setLoader((prev) => {
                    return {
                        ...prev,
                        convo: false,
                    };
                });
            });
    };

    const getChat = (id, page, item, convoIndex) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "POST",
            data: {
                user_id: id ? id : null,
                conversation_id: item?.conversation_id
                    ? item?.conversation_id
                    : 0,
                page,
            },
        };
        setLoader((prev) => {
            return { ...prev, chat: true, overlay: page == 1 ? true : false };
        });
        XHR(`${hostname}/api/v1/getChat?page=${page}`, options)
            .then((resp) => {
                if (resp?.data) {
                    // set count zero
                    setConverstaions((prev) => {
                        const temp = [...prev.conversations];
                        if (
                            convoIndex != null &&
                            convoIndex >= 0 &&
                            convoIndex < temp.length
                        ) {
                            const convoItem = { ...temp[convoIndex] };
                            if (convoItem.read_count > 0) {
                                convoItem.read_count = 0;
                                temp[convoIndex] = convoItem;
                                return { ...prev, conversations: temp };
                            }
                        }
                        return prev;
                    });

                    // console.log(resp.data);
                    const { chat, chat_current_page, chat_last_page } =
                        resp.data;

                    setChat((prev) => {
                        return {
                            ...prev,
                            ...resp.data,
                            chat: [...prev.chat, ...chat],
                            chat_current_page,
                            chat_last_page,
                        };
                    });
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setLoader((prev) => {
                    return { ...prev, chat: false, overlay: false };
                });
            });
    };

    const getConnections = () => {
        if (loader.connections) return;
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "GET",
        };
        setLoader((prev) => {
            return {
                ...prev,
                connections: true,
            };
        });
        XHR(
            `${hostname}/api/v1/getFamilyAndConnections?user_id=${user?.id}`,
            options
        )
            .then((resp) => {
                if (resp?.data?.family_and_connections) {
                    setConnectionList(resp.data.family_and_connections);
                    // console.log(resp.data);
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setLoader((prev) => {
                    return {
                        ...prev,
                        connections: false,
                    };
                });
            });
    };

    const sidebarStyle = {
        position: isSmallScreen ? "absolute" : "relative",
        zIndex: 2,
        top: isSmallScreen ? "var(--header-height)" : 0,
        transform: isSmallScreen
            ? showConversations
                ? "translateX(0)"
                : "translateX(-100%)"
            : "translateX(0)", // Sidebar is always visible on larger screens
        // borderRight: "1px solid rgb(79, 76, 76)",
    };

    const connectionSidebarStyle = {
        position: "fixed", // Use fixed for consistent positioning relative to the viewport.
        zIndex: 2, // Ensure it's above other elements.
        top: "var(--header-height)", // Align with the header height.
        right: 0, // Anchor to the right.
        height: "calc(100vh - var(--header-height))", // Fill the viewport below the header.
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Add shadow for depth.
        transform: showConnections ? "translateX(0)" : "translateX(100%)", // Slide in/out animation.
        transition: "transform 0.3s ease-in-out", // Smooth animation.
        left: "unset",
    };

    const contentStyle = {
        flexGrow: 1,
        transition: "margin-left 0.3s ease-in-out",
        width: "calc(100% - 320px)",
        // borderLeft:'1px solid rgb(79, 76, 76)'
    };

    const avatarStyle = {
        width: "48px",
        height: "48px",
        fontSize: "1.2rem",
    };

    const handleConversationClick = (item, index) => {
        // Api call to reset read count to zero at backend
        if (item?.conversation_id !== chat?.conversation_id) {
            apiCall(chat);
        }
        //

        if (loader.chat) return;

        if (isSmallScreen) {
            setShowConversations(false);
        }

        // Neglect api call if same conversation pressed
        if (item?.conversation_id == chat?.conversation_id) {
            return;
        }

        setChat((prev) => {
            return {
                ...prev,
                ...item,
                name:
                    item?.type !== "group"
                        ? item?.chat_user_username
                        : item?.conversation_name,
                image:
                    item?.type !== "group"
                        ? item?.chat_user_profile_pic
                        : item?.conversation_image,
                type: item?.type,
                chat:
                    item?.conversation_id !== chat?.conversation_id
                        ? []
                        : prev.chat,
            };
        });
        getChat(item?.chat_user_id, 1, item, index);
    };

    const handleNewConversation = (item) => {
        const index = conversations.conversations.findIndex(
            (element) =>
                element?.type == "individual" &&
                element?.chat_user_id == item?.id
        );

        const alreadyExistedChat =
            index !== -1 ? conversations.conversations[index] : null;

        setShowConnections(false);
        if (isSmallScreen) {
            setShowConversations(false);
        }
        if (alreadyExistedChat) {
            handleConversationClick(alreadyExistedChat, index);
            return;
        }
        //

        if (loader.chat) return;
        createNewConvo(item, 1);
    };

    const createNewConvo = (item, page) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "POST",
            data: {
                user_id: item?.id,
                conversation_id: 0,
                page,
            },
        };
        setLoader((prev) => {
            return { ...prev, chat: true, overlay: page == 1 ? true : false };
        });
        XHR(`${hostname}/api/v1/getChat?page=${page}`, options)
            .then((resp) => {
                if (resp?.data) {
                    // console.log(resp.data);
                    const { chat, chat_current_page, chat_last_page } =
                        resp.data;
                    console.log("RESP>>", resp?.data);
                    setChat({
                        name: item?.username,
                        image: item?.profile_pic,
                        type: "individual",
                        chat,
                        chat_current_page,
                        chat_last_page,
                        chat_user_id: item?.id,
                        conversation_id: null,
                    });
                    setConverstaions((prev) => {
                        // Making a new convo item to append on top of list
                        const convoItem = {
                            conversation_id: null,
                            type: "individual",
                            chat_user_profile_pic: item?.profile_pic,
                            chat_user_username: item?.username,
                            conversation_created_at: new Date(),
                            read_count: 0,
                            chat_user_id: item?.id,
                        };
                        const temp = [convoItem, ...prev.conversations];

                        return {
                            ...prev,
                            conversations: temp,
                        };
                    });
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setLoader((prev) => {
                    return { ...prev, chat: false, overlay: false };
                });
            });
    };

    const onEndConvo = () => {
        if (
            conversations.conversations_current_page <
                conversations.conversations_last_page &&
            !loader.convo
        ) {
            const nextPage = conversations.conversations_current_page + 1;
            getChatList(nextPage);
        }
    };

    const onEndChat = () => {
        if (chat.chat_current_page < chat.chat_last_page && !loader.chat) {
            const nextPage = chat.chat_current_page + 1;
            getChat(chat?.chat_user_id, nextPage, chat);
        }
    };

    // Function to scroll to the bottom
    const scrollToBottom = () => {
        const scrollableDiv = document.getElementById("scrollableDiv");
        if (scrollableDiv) {
            scrollableDiv.scrollTo({
                top: scrollableDiv.scrollHeight,
                behavior: "smooth",
            });
        }
    };

    // SEND API
    const sendMessage = () => {
        const msgId = new Date().getTime();
        const data = {
            attachment_file: null,
            offlineImg: selectedImg ? selectedImg : null,
            content: message,
            created_at: msgId,
            deleted_at: null,
            msg_id: msgId,
            id: msgId,
            profile_pic: null,
            reactions: [],
            sender_user_id: user?.id,
            username: user?.username,
            status: "pending",
        };
        setChat((prev) => {
            return {
                ...prev,
                chat: [data, ...prev.chat],
            };
        });
        setMessage("");
        setTimeout(() => {
            scrollToBottom();
        }, 100);

        setSelectedImg(null);

        const formdata = new FormData();
        formdata.append("msg_id", msgId);
        formdata.append("user_id", user?.id);
        formdata.append("reciever_id", chat?.chat_user_id);
        formdata.append("content", message);
        formdata.append(
            "conversation_id",
            chat?.conversation_id ? chat?.conversation_id : 0
        );
        formdata.append("type", selectedImg === null ? "text" : "image");
        if (selectedImg) {
            formdata.append("file", selectedImg);
        } else {
            formdata.append("file", null);
        }
        const options = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: formdata,
        };
        XHR(`${hostname}/api/v1/sendMessage`, options)
            .then((response) => {
                if (response.data) {
                    console.log("RESP>", response.data);
                }
            })
            .catch((error) => {
                setChat((prev) => {
                    const temp = prev.chat.map((item) =>
                        item?.msg_id == msgId
                            ? { ...data, status: "failed" }
                            : item
                    );
                    return {
                        ...prev,
                        chat: temp,
                    };
                });
                console.log("Error Config:", JSON.stringify(error?.config));
            });
    };
    // DEL API
    const delMsg = (msgId) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: { message_id: msgId },
        };
        XHR(`${hostname}/api/v1/deleteMessage`, options)
            .then((response) => {
                if (response.data) {
                }
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            });
    };
    // EDIT API
    const handleEditMsg = () => {
        const formdata = new FormData();
        formdata.append("content", editMsg.msg);
        formdata.append("message_id", editMsg.id);
        const options = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: formdata,
        };
        setLoader((prev) => {
            return {
                ...prev,
                editMsg: true,
            };
        });
        XHR(`${hostname}/api/v1/editMessage`, options)
            .then((response) => {
                if (response.data) {
                    setEditMsg({ msg: "", id: "" });
                }
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            })
            .finally(() => {
                setLoader((prev) => {
                    return {
                        ...prev,
                        editMsg: false,
                    };
                });
            });
    };

    const handleReactionApi = (msgId, reactionId) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: {
                message_id: msgId,
                reaction_id: reactionId,
            },
        };
        XHR(`${hostname}/api/v1/updateMessageReaction`, options)
            .then((response) => {
                if (response.data.success) {
                }
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            });
    };

    const RenderItem = useCallback(
        ({
            item,
            hostname,
            placeholder,
            removeStartingSlash,
            user,
            chat,
            delMsg,
            setEditMsg,
            setVisibleReactions,
            visibleReactions,
            handleReactionApi,
        }) => {
            return item?.type === "day" ? (
                <div
                    className="d-flex my-2"
                    style={{
                        alignSelf: "center",
                        backgroundColor: "#656565",
                        paddingBlock: 4,
                        paddingInline: 10,
                        borderRadius: 6,
                    }}
                >
                    <p
                        style={{
                            color: "#d9d9d9",
                            fontWeight: "bold",
                        }}
                    >
                        {moment(item?.date).format("MMMM DD, YYYY")}
                    </p>
                </div>
            ) : (
                <ChatBubble
                    item={item}
                    hostname={hostname}
                    placeholder={placeholder}
                    removeStartingSlash={removeStartingSlash}
                    user={user}
                    chat={chat}
                    delMsg={delMsg}
                    setEditMsg={setEditMsg}
                    setVisibleReactions={setVisibleReactions}
                    visibleReactions={visibleReactions}
                    handleReactionApi={handleReactionApi}
                />
            );
        },
        []
    );

    const debouncedSetMessage = useCallback(
        _.debounce((text) => {
            setSearchQuery(text);
            if (conversations.conversations.length > 0) {
                filterData(text);
            }
        }, 700),
        [conversations.conversations]
    );

    const filterData = useCallback(
        (text) => {
            const filtered =
                conversations &&
                conversations?.conversations?.filter((item) => {
                    if (item.type === "group") {
                        return item?.conversation_name
                            ?.toLowerCase()
                            ?.includes(text.toLowerCase());
                    } else {
                        return item?.chat_user_username
                            ?.toLowerCase()
                            ?.includes(text.toLowerCase());
                    }
                });

            if (filtered.length > 0) {
                const removedDuplicates = filtered.filter(
                    (item, index, self) =>
                        index ===
                        self.findIndex(
                            (t) => t.conversation_id === item.conversation_id
                        ) // Use the id or another unique property
                );
                removedDuplicates && setFilteredData(removedDuplicates);
            } else {
                setFilteredData([]);
            }
        },
        [conversations.conversations]
    );

    const debouncedConnectionSetMessage = useCallback(
        _.debounce((text) => {
            setConnectionSearchQuery(text);
            if (connectionList.length > 0) {
                filterConnections(text);
            }
        }, 700),
        [connectionList]
    );

    const filterConnections = useCallback(
        (text) => {
            const filtered = connectionList?.filter((item) => {
                return item?.username
                    ?.toLowerCase()
                    ?.includes(text.toLowerCase());
            });

            if (filtered.length > 0) {
                const removedDuplicates = filtered.filter(
                    (item, index, self) =>
                        index === self.findIndex((t) => t.id === item.id) // Use the id or another unique property
                );
                removedDuplicates && setFilteredConnections(removedDuplicates);
            } else {
                setFilteredConnections([]);
            }
        },
        [connectionList]
    );

    const apiCall = (data) => {
        if (!data?.chat_user_id && !data?.conversation_id) {
            return;
        }
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "POST",
            data: {
                user_id: data?.chat_user_id ? data.chat_user_id : null,
                conversation_id: data?.conversation_id
                    ? data.conversation_id
                    : 0,
                page: 1,
            },
        };
        XHR(`${hostname}/api/v1/getChat?page=${1}`, options)
            .then((resp) => {
                if (resp?.data) {
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            });
    };

    const deleteConversation = () => {
        const conversationId =
            conversations.conversations[isHovered]?.conversation_id;

        if (!conversationId) {
            setConverstaions((prev) => {
                const temp = [...prev.conversations];
                temp.splice(isHovered, 1);
                setShowDeleteModal(false);
                setIsHovered(null);
                toast.success("Chat Removed.");
                setChat({
                    name: "",
                    chat: [],
                    image: null,
                    type: "",
                    chat_current_page: 1,
                    chat_last_page: 1,
                });
                return {
                    ...prev,
                    conversations: temp,
                };
            });
            return;
        }

        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "POST",
            data: { conversationId },
        };
        setLoader((prev) => {
            return {
                ...prev,
                deleteCovo: true,
            };
        });
        XHR(`${hostname}/api/v1/deleteConversation`, options)
            .then((resp) => {
                if (resp?.data?.message) {
                    setShowDeleteModal(false);
                    setIsHovered(null);
                    toast.success(resp?.data?.message, { duration: 3000 });
                    setConverstaions((prev) => {
                        const index = prev.conversations.findIndex(
                            (convo) => convo?.conversation_id == conversationId
                        );
                        // Extract the object prior to the matching conversationId to set as selected after deletion
                        let previousObject = null;
                        if (index > 0) {
                            previousObject = prev.conversations[index - 1];
                        }
                        const filtered = prev.conversations.filter(
                            (convo) => convo?.conversation_id !== conversationId
                        );
                        const data = {
                            ...prev,
                            conversations: filtered,
                        };
                        // Set previous index conversation as selected if available any
                        if (chat?.name && previousObject) {
                            console.log("OBJ>>", previousObject);

                            const tempData = {
                                ...previousObject,
                                chat: [],
                                name:
                                    previousObject?.type !== "group"
                                        ? previousObject?.chat_user_username
                                        : previousObject?.conversation_name,
                                image:
                                    previousObject?.type !== "group"
                                        ? previousObject?.chat_user_profile_pic
                                        : previousObject?.conversation_image,
                                type: previousObject?.type,
                            };
                            setChat(tempData);
                            // Retrieve chat
                            getChat(
                                previousObject?.chat_user_id,
                                1,
                                previousObject,
                                prev?.conversations[index - 1]
                            );
                        } else {
                            setChat({
                                name: "",
                                chat: [],
                                image: null,
                                type: "",
                                chat_current_page: 1,
                                chat_last_page: 1,
                            });
                        }

                        return data;
                    });
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setLoader((prev) => {
                    return {
                        ...prev,
                        deleteCovo: false,
                    };
                });
            });
    };

    return (
        <div className="chat-wrapper">
            {/* Left sidebar - Conversations */}
            <div
                className={`${
                    darkMode ? "bg-black" : "bg-white"
                } conversation-sidebar`}
                style={sidebarStyle}
            >
                <div
                    className="p-3 d-flex align-items-center justify-content-between"
                    style={{
                        // borderBottom: "1px solid #4f4c4c",
                        height: 72,
                    }}
                >
                    <div className="d-flex gap-2">
                        {isSmallScreen && (
                            <button
                                className="btn btn-link d-lg-none text-dark p-0"
                                onClick={() => setShowConversations(false)}
                            >
                                <ArrowLeft
                                    size={24}
                                    color={darkMode ? "#fff" : "#000"}
                                />
                            </button>
                        )}
                        <h5
                            className="mb-0"
                            style={{ color: darkMode ? "#fff" : "#000" }}
                        >
                            Chats
                        </h5>
                    </div>

                    <button
                        className="btn btn-link text-dark p-0"
                        onClick={() => {
                            getConnections();
                            setShowConnections(true);
                        }}
                    >
                        <MessageSquarePlus
                            size={24}
                            color={darkMode ? "#fff" : "#000"}
                        />
                    </button>
                </div>

                <div
                    className="p-3"
                    style={{ borderBottom: "1px solid #4f4c4c" }}
                >
                    <div className="position-relative">
                        <Search
                            className="position-absolute text-secondary"
                            style={{ left: "10px", top: "10px" }}
                        />
                        <input
                            type="text"
                            onChange={(e) =>
                                debouncedSetMessage(e.target.value)
                            }
                            placeholder="Search conversations..."
                            className="form-control ps-5"
                        />
                    </div>
                </div>

                <div
                    className="convo-cont"
                    style={{ height: "calc(100vh - 210px)", overflowY: "auto" }}
                >
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={onEndConvo}
                        hasMore={
                            conversations.conversations_current_page <
                            conversations.conversations_last_page
                        }
                        useWindow={false}
                    >
                        {(searchQuery.length > 0
                            ? filteredData
                            : conversations.conversations
                        ).map((item, index) => (
                            <ConversationComp
                                key={index}
                                handleConversationClick={
                                    handleConversationClick
                                }
                                index={index}
                                item={item}
                                chat={chat}
                                setIsHovered={setIsHovered}
                                isHovered={isHovered}
                                showModal={setShowDeleteModal}
                                darkMode={darkMode}
                            />
                        ))}
                        {loader.convo && (
                            <div className="d-flex align-items-center justify-content-center py-3">
                                <Circles
                                    height="40"
                                    width="40"
                                    color="#347bff"
                                    ariaLabel="circles-loading"
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                        )}
                    </InfiniteScroll>
                </div>
            </div>

            {/* Mid Section - Chat messages */}
            <div
                className="d-flex flex-column"
                style={{ ...contentStyle, position: "relative" }}
            >
                {loader.overlay && <ChatOverayLoader />}
                {/* Chat header */}
                <div
                    className="p-3 shadow-sm"
                    style={{
                        //  borderBottom: "1px solid #4f4c4c",
                        height: 72,

                        backgroundColor: "#707cae61",
                    }}
                >
                    <div className="d-flex align-items-center">
                        {isSmallScreen && (
                            <button
                                className="btn btn-link text-dark p-0 me-2"
                                onClick={() => setShowConversations(true)}
                            >
                                <MenuIcon size={24} color="#fff" fill="#fff" />
                            </button>
                        )}
                        {chat.name && (
                            <div
                                className="rounded-circle bg-primary bg-opacity-10 text-primary d-flex align-items-center justify-content-center"
                                style={{ width: "40px", height: "40px" }}
                            >
                                {chat.image ? (
                                    <img
                                        style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: 50,
                                            objectFit: "cover",
                                        }}
                                        src={`${hostname}/${removeStartingSlash(
                                            chat.image
                                        )}`}
                                    />
                                ) : chat.type === "group" ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            backgroundColor: "grey",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: 50,
                                            height: 40,
                                            width: 40,
                                        }}
                                    >
                                        <UsersRound
                                            size={24}
                                            color="#fff"
                                            fill="#fff"
                                        />
                                    </div>
                                ) : (
                                    <img
                                        style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: 50,
                                            objectFit: "cover",
                                        }}
                                        src={placeholder}
                                    />
                                )}
                            </div>
                        )}
                        <div className="ms-3">
                            <h5 className="mb-0">{chat.name}</h5>
                        </div>
                    </div>
                </div>

                {/* Messages area */}
                <div
                    className="p-3 message-cont"
                    id="scrollableDiv"
                    style={{
                        height: "calc(100vh - 160px)",
                        overflowY: "auto",
                        // backgroundColor: "#707cae61",
                        backgroundColor: "#3e3f4161",
                        display: "flex",
                        flexDirection: "column-reverse",
                    }}
                >
                    <InfiniteScrollComp
                        dataLength={chat.chat.length}
                        next={onEndChat}
                        hasMore={chat.chat_current_page < chat.chat_last_page}
                        style={{
                            display: "flex",
                            flexDirection: "column-reverse",
                            overflow: "unset",
                        }}
                        inverse={true}
                        loader={
                            <div className="d-flex align-items-center justify-content-center py-3">
                                <Circles
                                    height="30"
                                    width="30"
                                    color="#347bff"
                                    ariaLabel="circles-loading"
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                        }
                        scrollableTarget="scrollableDiv"
                    >
                        {generateItems(chat.chat).map((item, index) => {
                            return (
                                <RenderItem
                                    key={index}
                                    chat={chat}
                                    delMsg={delMsg}
                                    handleReactionApi={handleReactionApi}
                                    hostname={hostname}
                                    item={item}
                                    placeholder={placeholder}
                                    removeStartingSlash={removeStartingSlash}
                                    setEditMsg={setEditMsg}
                                    setVisibleReactions={setVisibleReactions}
                                    user={user}
                                    visibleReactions={visibleReactions}
                                />
                            );
                        })}
                    </InfiniteScrollComp>
                </div>

                {/* Message input */}
                <div
                    className="d-flex flex-column p-3 mt-auto"
                    // style={{ backgroundColor: "#707cae61" }}
                >
                    {editMsg.id && (
                        <div className="d-flex gap-2 align-items-center justify-content-between ">
                            <p style={{ color: "white" }}>Editing Message</p>
                            <X
                                onClick={() => setEditMsg({ id: "", msg: "" })}
                                size={20}
                                color="#fff"
                            />
                        </div>
                    )}
                    {selectedImg && (
                        <div
                            className="d-flex"
                            style={{
                                position: "relative",
                                borderRadius: 10,
                                border: "1px solid grey",
                                alignSelf: "flex-start",
                                padding: 6,
                            }}
                        >
                            <img
                                src={selectedImg}
                                style={{
                                    height: 55,
                                    width: 55,
                                    objectFit: "cover",
                                    borderRadius: 10,
                                }}
                            />
                            <X
                                onClick={() => setSelectedImg(null)}
                                size={20}
                                color="#fff"
                                style={{
                                    position: "absolute",
                                    top: -7,
                                    right: -7,
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    )}
                    <div className="d-flex gap-2 py-2 align-items-center">
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{
                                display: "none",
                            }}
                            onChange={handleFileChange}
                            accept="image/*"
                        />
                        <Paperclip
                            onClick={() => handleButtonClick()}
                            size={26}
                            color={darkMode ? "#fff" : "#000"}
                            style={{ cursor: "pointer" }}
                        />
                        <input
                            type="text"
                            value={editMsg.id ? editMsg.msg : message}
                            onChange={(e) => {
                                if (editMsg.id) {
                                    setEditMsg((prev) => {
                                        return {
                                            ...prev,
                                            msg: e.target.value,
                                        };
                                    });
                                } else {
                                    setMessage(e.target.value);
                                }
                            }}
                            placeholder="Type a message..."
                            className="form-control"
                            onKeyPress={(e) => {
                                if (
                                    e.key !== "Enter" || // Key is not "Enter"
                                    (!chat?.conversation_id &&
                                        !chat?.chat_user_id) // Neither conversation_id nor chat_user_id is available
                                ) {
                                    return;
                                }

                                const isEditMode = Boolean(editMsg?.id);
                                const hasValidContent = isEditMode
                                    ? selectedImg || editMsg?.msg?.trim()
                                    : selectedImg || message?.trim();
                                const isLoading =
                                    loader.chat ||
                                    loader.convo ||
                                    loader.overlay ||
                                    loader.editMsg;

                                if (isLoading) return;

                                if (isEditMode && hasValidContent) {
                                    handleEditMsg();
                                } else if (!isEditMode && hasValidContent) {
                                    sendMessage();
                                }
                            }}
                        />
                        <button
                            disabled={
                                // Check if any loader is active
                                loader.chat ||
                                loader.convo ||
                                loader.overlay ||
                                // Check if conversation_id exists or if user_id is present
                                (!chat?.conversation_id &&
                                    !chat?.chat_user_id) || // If neither exists, disable
                                // For edit mode (when editMsg.id exists)
                                (editMsg.id
                                    ? !selectedImg && !editMsg.msg.trim() // In edit mode, require either image or message
                                    : !selectedImg && !message.trim()) // In normal mode, require either image or message
                            }
                            onClick={() => {
                                if (
                                    selectedImg ||
                                    (message.trim() && !editMsg.id)
                                ) {
                                    sendMessage();
                                } else if (
                                    editMsg.msg.trim() &&
                                    !loader.editMsg
                                ) {
                                    handleEditMsg();
                                }
                            }}
                            className="btn btn-primary d-flex align-items-center justify-content-center"
                            style={{ width: "42px", height: "42px" }}
                        >
                            {loader.editMsg ? (
                                <Circles
                                    height="20"
                                    width="20"
                                    color="#fff"
                                    ariaLabel="circles-loading"
                                    wrapperClass=""
                                    visible={true}
                                />
                            ) : (
                                <Send size={20} />
                            )}
                        </button>
                    </div>
                </div>
            </div>
            {/* Right Connections */}
            <div
                className={`${
                    darkMode ? "bg-black" : "bg-white"
                } conversation-sidebar `}
                style={connectionSidebarStyle}
            >
                <div
                    className="p-3 d-flex align-items-center justify-content-between"
                    style={{
                        height: 72,
                    }}
                >
                    <h5
                        className="mb-0"
                        style={{ color: darkMode ? "#fff" : "#000" }}
                    >
                        New Conversation
                    </h5>
                    <X
                        onClick={() => setShowConnections(false)}
                        size={24}
                        color={darkMode ? "#fff" : "#000"}
                        style={{ cursor: "pointer" }}
                    />
                </div>

                <div
                    className="p-3"
                    style={{ borderBottom: "1px solid #4f4c4c" }}
                >
                    <div className="position-relative">
                        <Search
                            className="position-absolute text-secondary"
                            style={{ left: "10px", top: "10px" }}
                        />
                        <input
                            type="text"
                            onChange={(e) =>
                                debouncedConnectionSetMessage(e.target.value)
                            }
                            placeholder="Search connection..."
                            className="form-control ps-5"
                        />
                    </div>
                </div>

                <div
                    className="convo-cont"
                    style={{ height: "calc(100vh - 210px)", overflowY: "auto" }}
                >
                    <InfiniteScroll
                        pageStart={0}
                        hasMore={false}
                        useWindow={false}
                    >
                        {(connectionSearchQuery.length > 0
                            ? filteredConnections
                            : connectionList
                        ).map((item, index) => (
                            <div
                                key={item?.id}
                                className="p-3 convo-item"
                                style={{
                                    backgroundColor: darkMode
                                        ? "#1b1b1b"
                                        : "#fff",
                                }}
                                onClick={() => handleNewConversation(item)}
                            >
                                <div
                                    className="rounded-circle bg-primary bg-opacity-10 text-primary d-flex align-items-center justify-content-center flex-shrink-0"
                                    style={avatarStyle}
                                >
                                    <img
                                        style={{
                                            height: 48,
                                            width: 48,
                                            borderRadius: 50,
                                            objectFit: "cover",
                                        }}
                                        src={
                                            item?.profile_pic
                                                ? `${hostname}/${removeStartingSlash(
                                                      item.profile_pic
                                                  )}`
                                                : placeholder
                                        }
                                    />
                                </div>
                                <div
                                    className="ms-3 flex-grow-1 min-width-0"
                                    style={{ maxWidth: 220 }}
                                >
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="mb-0 text-truncate">
                                            {item?.username}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {loader.connections && (
                            <div className="d-flex align-items-center justify-content-center py-3">
                                <Circles
                                    height="40"
                                    width="40"
                                    color="#347bff"
                                    ariaLabel="circles-loading"
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                        )}
                    </InfiniteScroll>
                </div>
            </div>
            {/* Chat Delete Modal */}
            <ChatDeleteModal
                deleteConversation={deleteConversation}
                loader={loader.deleteCovo}
                show={deleteModal}
                onHide={setShowDeleteModal}
            />
        </div>
    );
};

export default ChatInterface;
