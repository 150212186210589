import React, { useCallback, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { removeStartingSlash as removeSlash } from "../../utills/RemoveSlash.js";
import placeholder from "../../assets/images/placeholder.png";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import LikeBar from "../../components/Reactions/LikeBar.js";
import Popup from "reactjs-popup";
import toast from "react-hot-toast";
import { Camera, MoveLeft } from "lucide-react";
import { Circles } from "react-loader-spinner";
import { database } from "../../firebase/firebaseConfig.js";
import { ref, onChildAdded, off, onValue } from "firebase/database";
import { useDispatch, useSelector } from "react-redux";
import XHR from "../../utills/XHR.js";
import { updateUniversalStyles } from "../../store/slices/bodyStyling.js";

export default function UserProfile({ darkMode }) {
    const [userData, setUserData] = useState(false);
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.login.userData);

    const dispatch = useDispatch();

    // const hostname = "redefine.church";
    const hostname = process.env.REACT_APP_BASE_URL;
    const postTypes = [
        { type: "Question" },
        { type: "Prayer Request" },
        { type: "Encouragement" },
    ];
    const [postType, setType] = useState("Question");
    const [text, setText] = useState("");
    const [imageFile, setFile] = useState(null);
    const [privacyType, setPrivacyType] = useState("1");
    const [selectedImg, setSelectedImg] = useState(null);
    const fileInputRef = useRef(null);
    const bannerInputRef = useRef(null);
    const profileInputRef = useRef(null);

    const [bannerImg, setBannerImg] = useState(null);
    const [profileImg, setProfileImg] = useState(null);
    const [bannerFile, setBannerFile] = useState(null);
    const [profileFile, setProfileFile] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [editLoader, setEditLoader] = useState(false);

    const privacyTypes = [
        { type: "Public", value: 1 },
        { type: "Connection only", value: 2 },
        { type: "Family only", value: 3 },
        { type: "Only me", value: 4 },
    ];

    useEffect(() => {
        getUserData();
    }, []);

    let newItems = false;

    useEffect(() => {
        // add posts comment listener
        const PostCommentRef = ref(database, "PostCommentAdded");
        onChildAdded(PostCommentRef, handlePostCommment);
        // add posts listener
        const PostReactionChangedRef = ref(database, "PostReactionChanged");
        onChildAdded(PostReactionChangedRef, handlePostReaction);

        // handle initial items
        // onValue(addPostRef, handleInitialItems)
        onValue(PostReactionChangedRef, handleInitialItems);
    }, []);

    const handleInitialItems = () => {
        newItems = true;
    };

    const handlePostCommment = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            const e = snapshot.val();
            console.log("Comment added>", e);

            if (e.is_reply == 0) {
                /*@ts-ignore */
                setUserData((prev) => {
                    /* @ts-ignore*/
                    const index = prev?.posts?.findIndex(
                        (item) => item.id == e.post_id
                    );
                    if (index !== -1) {
                        /* @ts-ignore*/
                        const newArray = [...prev?.posts];
                        const commentCount = newArray[index]?.comment_count + 1;
                        /* @ts-ignore*/
                        newArray[index] = {
                            ...newArray[index],
                            comment_count: commentCount,
                        };
                        /* @ts-ignore*/
                        const updatedData = { ...prev, posts: newArray };
                        return updatedData;
                    }
                    return prev;
                });
            }
        }
    };

    const handlePostReaction = (snapshot) => {
        if (!newItems) return;
        const loggedinUserId = user.id;
        if (snapshot.exists()) {
            console.log("Post reaction", snapshot.val());
            const e = snapshot.val();
            if (e) {
                const { postId, userId, action } = e;
                /*@ts-ignore */
                setUserData((prev) => {
                    /*@ts-ignore */
                    const postIndex = prev?.posts?.findIndex(
                        (item) => item?.id == postId
                    );
                    /*@ts-ignore */
                    const postObj = prev?.posts?.[postIndex];
                    if (postIndex !== -1) {
                        if (
                            action === "deleted" &&
                            postObj?.reaction_count > 0
                        ) {
                            const updatedObj = {
                                ...postObj,
                                reaction_count: --postObj.reaction_count,
                                is_reacted:
                                    userId == loggedinUserId
                                        ? false
                                        : postObj.is_reacted,
                            };
                            /*@ts-ignore */
                            const updatePostsArray = [...prev.posts];
                            updatePostsArray[postIndex] = updatedObj;
                            /*@ts-ignore */
                            const tempData = {
                                ...prev,
                                posts: updatePostsArray,
                            };
                            return tempData;
                        } else if (action === "added") {
                            const updatedObj = {
                                ...postObj,
                                reaction_count: ++postObj.reaction_count,
                                is_reacted:
                                    userId == loggedinUserId
                                        ? true
                                        : postObj.is_reacted,
                            };
                            /*@ts-ignore */
                            const updatePostsArray = [...prev.posts];
                            updatePostsArray[postIndex] = updatedObj;
                            /*@ts-ignore */
                            const tempData = {
                                ...prev,
                                posts: updatePostsArray,
                            };
                            return tempData;
                        }
                    }
                    return prev;
                });
            }
        }
    };

    const getUserData = () => {
        try {
            const options = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user.api_token}`,
                },
                method: "GET",
            };
            setLoader(true);
            XHR(
                `${hostname}/api/v1/getUserProfile?user_id=${user?.id}`,
                options
            )
                .then((resp) => {
                    console.log("RESP>>", resp?.data);
                    if (resp.data.success) {
                        setUserData(resp.data);
                        dispatch(
                            updateUniversalStyles(resp?.data?.universal_styles)
                        );
                    }
                })
                .catch((e) => {
                    console.log("ERR>", e);
                })
                .finally(() => {
                    setLoader(false);
                });
        } catch (error) {
            console.log("ERR>", error);
        }
    };

    const handleButtonClick = () => {
        // Trigger the file input
        fileInputRef?.current?.click();
    };
    const handleProfilPicClick = () => {
        // Trigger the file input
        profileInputRef?.current?.click();
    };
    const handleBannerPicClick = () => {
        // Trigger the file input
        bannerInputRef?.current?.click();
    };

    const handleFileChange = (e) => {
        // Handle the selected file
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            console.log("Selected Image:", selectedFile);
            if (selectedFile.type.startsWith("image/")) {
                setFile(selectedFile);

                const reader = new FileReader();
                reader.onload = (event) => {
                    setSelectedImg(event.target.result);
                };
                reader.readAsDataURL(selectedFile);
            } else {
                alert("Please select a valid image file.");
            }
        }
    };

    const handleProfilePic = (e) => {
        // Handle the selected file
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            console.log("Selected Image:", selectedFile);
            if (selectedFile.type.startsWith("image/")) {
                setProfileFile(selectedFile);

                const reader = new FileReader();
                reader.onload = (event) => {
                    setProfileImg(event.target.result);
                };
                reader.readAsDataURL(selectedFile);
            } else {
                alert("Please select a valid image file.");
            }
        }
    };

    const handleBannerPic = (e) => {
        // Handle the selected file
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.type.startsWith("image/")) {
                setBannerFile(selectedFile);
                const reader = new FileReader();
                reader.onload = (event) => {
                    setBannerImg(event.target.result);
                };
                reader.readAsDataURL(selectedFile);
            } else {
                alert("Please select a valid image file.");
            }
        }
    };

    const addPost = (close) => {
        if (selectedImg == null && text.trim().length == 0) {
            toast.error("Please add some content to post");
            return;
        }

        const formData = new FormData();
        if (selectedImg) {
            formData.append("post_img", selectedImg);
        }
        formData.append("user_id", user.id);
        formData.append("post_type", postType);
        formData.append("post_privacy", privacyType);
        formData.append("post_content", text);

        const options = {
            method: "post",
            url: `${hostname}/api/v1/addPost`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user.api_token}`,
            },
            data: formData,
        };

        XHR(options)
            .then((resp) => {
                if (resp.data.success) {
                    getUserData();
                    toast.success(resp.data.message);
                    setText("");
                    close();
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            });
    };

    const editProfile = () => {
        const formData = new FormData();
        if (bannerImg) {
            formData.append("banner_pic", bannerImg);
        }
        if (profileImg) {
            formData.append("profile_pic", profileImg);
        }

        const options = {
            method: "post",
            url: `${hostname}/api/v1/updateUserProfile`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user.api_token}`,
            },
            data: formData,
        };
        setEditLoader(true);
        XHR(options)
            .then((resp) => {
                if (resp.data.success) {
                    getUserData();
                    toast.success(resp.data.message);
                    setBannerFile(null);
                    setBannerImg(null);
                    setProfileImg(null);
                    setProfileFile(null);
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setEditLoader(false);
            });
    };

    const RenderLikeBar = useCallback(({ item }) => {
        return <LikeBar otherUserProfile={true} postData={item} edit={false} />;
    }, []);

    return (
        <section className="profilemain1">
            <div className="container">
                <div className="row">
                    <div
                        onClick={() => navigate(-1)}
                        className="col-md-2"
                        style={{ cursor: "pointer" }}
                    >
                        <MoveLeft
                            style={{ marginTop: 30 }}
                            size={30}
                            color={darkMode ? "#ffffff" : "#000"}
                            fill="#ffffff"
                        />
                    </div>
                    <div className="col-md-8">
                        <div className="newpromainwrap">
                            <div className="newpromaininner">
                                <div className="profilebannermain mt-4">
                                    <div className="bannerProfilephoto">
                                        {loader ? (
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{
                                                    overflow: "hidden",
                                                    borderRadius: 10,
                                                }}
                                            >
                                                <Skeleton
                                                    baseColor="#716e6e"
                                                    height={250}
                                                    width={2560}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="d-flex"
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                    position: "relative",
                                                }}
                                            >
                                                <img
                                                    src={
                                                        bannerImg
                                                            ? bannerImg
                                                            : userData
                                                                  ?.user_detail
                                                                  ?.banner_pic !==
                                                              null
                                                            ? `${hostname}/${removeSlash(
                                                                  userData
                                                                      ?.user_detail
                                                                      ?.banner_pic
                                                              )}`
                                                            : "https://images.ctfassets.net/1itkm9rji8jb/4B4goqdaK0JYabBZnu40Ir/68781d15f9fe8f564864a6b6a4d3116e/OXTE6R98U7.jpg"
                                                    }
                                                    alt=""
                                                />
                                                {isEdit && (
                                                    <div
                                                        onClick={() =>
                                                            handleBannerPicClick()
                                                        }
                                                        className="d-flex align-items-center justify-content-center"
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            right: 40,
                                                            bottom: 40,
                                                            backgroundColor:
                                                                "#383838",
                                                            borderRadius: 50,
                                                            padding: 6,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <Camera
                                                            size={40}
                                                            fill="#fff"
                                                            color="#000"
                                                        />
                                                        <input
                                                            type="file"
                                                            ref={bannerInputRef}
                                                            style={{
                                                                display: "none",
                                                            }}
                                                            onChange={
                                                                handleBannerPic
                                                            }
                                                            accept="image/*"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="profileimageEditprofilemain">
                                        <div className="prfileimageconnection">
                                            <div className="profileimage">
                                                {loader ? (
                                                    <Skeleton
                                                        circle
                                                        baseColor="#716e6e"
                                                        height={140}
                                                        width={140}
                                                    />
                                                ) : (
                                                    <div
                                                        className="d-flex"
                                                        style={{
                                                            height: "100%",
                                                            width: "100%",
                                                            position:
                                                                "relative",
                                                        }}
                                                    >
                                                        <img
                                                            className="profilePic"
                                                            src={
                                                                profileImg
                                                                    ? profileImg
                                                                    : userData
                                                                          ?.user_detail
                                                                          ?.profile_pic !==
                                                                      null
                                                                    ? `${hostname}/${removeSlash(
                                                                          userData
                                                                              ?.user_detail
                                                                              ?.profile_pic
                                                                      )}`
                                                                    : placeholder
                                                            }
                                                            alt=""
                                                        />
                                                        {isEdit && (
                                                            <div
                                                                onClick={() => {
                                                                    handleProfilPicClick();
                                                                }}
                                                                className="d-flex align-items-center justify-content-center"
                                                                style={{
                                                                    position:
                                                                        "absolute",
                                                                    right: 15,
                                                                    bottom: 0,
                                                                    backgroundColor:
                                                                        "#383838",
                                                                    borderRadius: 50,
                                                                    padding: 6,
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <Camera
                                                                    size={30}
                                                                    fill="#fff"
                                                                    color="#000"
                                                                />
                                                                <input
                                                                    type="file"
                                                                    ref={
                                                                        profileInputRef
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            "none",
                                                                    }}
                                                                    onChange={
                                                                        handleProfilePic
                                                                    }
                                                                    accept="image/*"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="nameconnection">
                                                {loader ? (
                                                    <>
                                                        <Skeleton
                                                            baseColor="#716e6e"
                                                            height={15}
                                                            width={250}
                                                            style={{
                                                                marginTop: 10,
                                                            }}
                                                        />
                                                        <Skeleton
                                                            baseColor="#716e6e"
                                                            height={10}
                                                            width={200}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <h3
                                                            style={{
                                                                fontSize: 20,
                                                                fontWeight: 500,
                                                                lineHeight: 1.2,
                                                            }}
                                                        >
                                                            {
                                                                userData
                                                                    ?.user_detail
                                                                    ?.username
                                                            }
                                                        </h3>
                                                        <h5
                                                            style={{
                                                                fontSize: 16,
                                                                fontWeight:
                                                                    "500",
                                                            }}
                                                        >
                                                            {
                                                                userData
                                                                    ?.connection_detail
                                                                    ?.length
                                                            }{" "}
                                                            {userData
                                                                ?.connection_detail
                                                                ?.length == 1
                                                                ? "Connection"
                                                                : "Connections"}
                                                        </h5>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {loader ? (
                                            <Skeleton
                                                baseColor="#716e6e"
                                                height={50}
                                                width={170}
                                                style={{ marginTop: 20 }}
                                            />
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    setEdit(!isEdit);
                                                    if (
                                                        profileImg !== null ||
                                                        bannerImg !== null
                                                    ) {
                                                        editProfile();
                                                    }
                                                }}
                                                className="profileeditmain"
                                            >
                                                <p
                                                    className="hasnewbtn"
                                                    style={{ fontSize: 18 }}
                                                >
                                                    {editLoader ? (
                                                        <Circles
                                                            height="30"
                                                            width="30"
                                                            color="white"
                                                            ariaLabel="circles-loading"
                                                            wrapperClass=""
                                                            visible={true}
                                                        />
                                                    ) : isEdit ? (
                                                        "Save Changes"
                                                    ) : (
                                                        "Edit Profile"
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="profilesecionmainwrwap row">
                                    <div className="prosecleft col-md-4 ">
                                        <div className="aboutbox hasboxbg">
                                            {loader ? (
                                                <>
                                                    <Skeleton
                                                        baseColor="#716e6e"
                                                        height={10}
                                                        width={200}
                                                        style={{
                                                            marginTop: 10,
                                                        }}
                                                    />
                                                    <Skeleton
                                                        baseColor="#716e6e"
                                                        height={10}
                                                        width={150}
                                                    />
                                                    <Skeleton
                                                        baseColor="#716e6e"
                                                        height={10}
                                                        width={200}
                                                    />
                                                    <Skeleton
                                                        baseColor="#716e6e"
                                                        height={10}
                                                        width={150}
                                                    />
                                                    <Skeleton
                                                        baseColor="#716e6e"
                                                        height={10}
                                                        width={200}
                                                    />
                                                    <Skeleton
                                                        baseColor="#716e6e"
                                                        height={10}
                                                        width={150}
                                                    />
                                                    <Skeleton
                                                        baseColor="#716e6e"
                                                        height={10}
                                                        width={200}
                                                        style={{
                                                            marginBottom: 10,
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <div className="titleaboutLInk">
                                                        <h5>About</h5>
                                                    </div>
                                                    <div className="aboutifo">
                                                        <ul>
                                                            <li>
                                                                <span>
                                                                    Position:
                                                                </span>{" "}
                                                                {
                                                                    userData
                                                                        ?.user_detail
                                                                        ?.position_name
                                                                        ?.position_name
                                                                }
                                                            </li>
                                                            <li>
                                                                <span>
                                                                    Hometown:
                                                                </span>
                                                                {
                                                                    userData
                                                                        ?.user_detail
                                                                        ?.city
                                                                }
                                                            </li>
                                                            <li>
                                                                <span>
                                                                    Current
                                                                    City:
                                                                </span>
                                                                {
                                                                    userData
                                                                        ?.user_detail
                                                                        ?.city
                                                                }
                                                            </li>
                                                            <li>
                                                                <span>
                                                                    Status:
                                                                </span>
                                                                {
                                                                    userData
                                                                        ?.user_detail
                                                                        ?.marital_status
                                                                }
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="familybox hasboxbg">
                                            <div className="titleaboutLInk">
                                                <h5>Family</h5>
                                            </div>
                                            <div className="familyboxinfo row ">
                                                {userData?.family_detail?.map(
                                                    (item) => {
                                                        return (
                                                            <div
                                                                onClick={() => {
                                                                    navigate(
                                                                        "/otherUserProfile",
                                                                        {
                                                                            state: item.id,
                                                                        }
                                                                    );
                                                                }}
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                className="col-md-4"
                                                            >
                                                                <div className="imageFamily">
                                                                    <img
                                                                        src={
                                                                            item?.profile_pic !==
                                                                            null
                                                                                ? `${hostname}/${removeSlash(
                                                                                      item?.profile_pic
                                                                                  )}`
                                                                                : placeholder
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="nameFamily">
                                                                    <h5>
                                                                        {
                                                                            item?.username
                                                                        }
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                        {/*  */}
                                        <div className="familybox hasboxbg">
                                            <div className="titleaboutLInk">
                                                <h5>Connections</h5>
                                            </div>
                                            <div className="familyboxinfo row ">
                                                {userData?.connection_detail?.map(
                                                    (item) => {
                                                        return (
                                                            <div
                                                                onClick={() => {
                                                                    navigate(
                                                                        "/otherUserProfile",
                                                                        {
                                                                            state: item.id,
                                                                        }
                                                                    );
                                                                }}
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                className="col-md-4"
                                                            >
                                                                <div className="imageFamily">
                                                                    <img
                                                                        src={
                                                                            item?.profile_pic !==
                                                                            null
                                                                                ? `${hostname}/${removeSlash(
                                                                                      item?.profile_pic
                                                                                  )}`
                                                                                : placeholder
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="nameFamily">
                                                                    <h5>
                                                                        {
                                                                            item?.username
                                                                        }
                                                                    </h5>
                                                                    <h6>
                                                                        {
                                                                            item?.relation
                                                                        }
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>

                                        <div className="aboutbox hasboxbg">
                                            <div className="titleaboutLInk">
                                                <h5>Upcoming Events</h5>
                                            </div>
                                            <div className="upcomingifo">
                                                {userData?.upcoming_events?.map(
                                                    (item) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    navigate(
                                                                        "/upcomingEvent",
                                                                        {
                                                                            state: item,
                                                                        }
                                                                    );
                                                                }}
                                                                className="comingImageTitle mb-4"
                                                            >
                                                                <img
                                                                    src={
                                                                        item?.series_banner !==
                                                                        null
                                                                            ? `${hostname}/${removeSlash(
                                                                                  item?.series_banner
                                                                              )}`
                                                                            : "https://images.ctfassets.net/1itkm9rji8jb/2Awcn6y97oIY6GvEnRDYEn/a749e51fd78f564ff012f47326fb83b2/Rectangle_7777.jpg"
                                                                    }
                                                                    alt=""
                                                                />
                                                                <div className="upcmgtitle">
                                                                    <h5
                                                                        style={{
                                                                            color: darkMode
                                                                                ? "white"
                                                                                : "#000",
                                                                        }}
                                                                    >
                                                                        {
                                                                            item?.event_name
                                                                        }
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="prosecright col-md-8">
                                        <Popup
                                            // lockScroll={true}
                                            contentStyle={{
                                                border: "unset",
                                                padding: 0,
                                                borderRadius: 10,
                                            }}
                                            modal={true}
                                            onClose={() => {
                                                setSelectedImg(null);
                                                setText("");
                                            }}
                                            trigger={
                                                <div className="mindUpload hasboxbg">
                                                    <div className="uoloadtext">
                                                        <div className="profileimg">
                                                            <img
                                                                src={
                                                                    userData
                                                                        ?.user_detail
                                                                        ?.profile_pic !==
                                                                    null
                                                                        ? `${hostname}/${removeSlash(
                                                                              userData
                                                                                  ?.user_detail
                                                                                  ?.profile_pic
                                                                          )}`
                                                                        : placeholder
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="profileText">
                                                            <h4 style={{lineHeight:1.2}} >
                                                                What&#39;s on
                                                                your mind,
                                                                {
                                                                    userData
                                                                        ?.user_detail
                                                                        ?.username
                                                                }
                                                                ?
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="profileimgUploadIcon">
                                                        <img
                                                            src="https://images.ctfassets.net/1itkm9rji8jb/GBEcAOKhA2yzHVXL3RhZQ/f9c2723924c977f8c393e2025672d06d/image-gallery-svgrepo-com.svg"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            position="center center"
                                        >
                                            {(close) => (
                                                <div className="popupMain">
                                                    <div className="popupInner">
                                                        <div className="popupHeader d-flex">
                                                            <div>
                                                                <h5>
                                                                    Select Topic
                                                                </h5>
                                                                <ul
                                                                    style={{
                                                                        listStyle:
                                                                            "none",
                                                                    }}
                                                                >
                                                                    {postTypes.map(
                                                                        (
                                                                            item
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        item.id
                                                                                    }
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setType(
                                                                                            item.type
                                                                                        );
                                                                                    }}
                                                                                    className={
                                                                                        postType ===
                                                                                        item.type
                                                                                            ? "active"
                                                                                            : ""
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.type
                                                                                    }
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            <div>
                                                                <h5
                                                                    style={{
                                                                        width: "unset",
                                                                        marginBottom: 10,
                                                                    }}
                                                                >
                                                                    Select
                                                                    Privacy
                                                                </h5>
                                                                <select
                                                                    style={{
                                                                        height: 30,
                                                                        borderRadius: 4,
                                                                        backgroundColor:
                                                                            "dimgray",
                                                                    }}
                                                                    value={
                                                                        privacyType
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setPrivacyType(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                >
                                                                    {privacyTypes.map(
                                                                        (
                                                                            item
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        item.value
                                                                                    }
                                                                                    value={
                                                                                        item.value
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.type
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="mindUpload">
                                                            <div className="uoloadtext">
                                                                <div className="profileimg">
                                                                    <img
                                                                        src={
                                                                            userData
                                                                                ?.user_detail
                                                                                ?.profile_pic !==
                                                                            null
                                                                                ? `${hostname}/${removeSlash(
                                                                                      userData
                                                                                          ?.user_detail
                                                                                          ?.profile_pic
                                                                                  )}`
                                                                                : placeholder
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="profileText">
                                                                    <h4>
                                                                        {
                                                                            userData
                                                                                ?.user_detail
                                                                                ?.username
                                                                        }
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    handleButtonClick();
                                                                }}
                                                                // className="profileimgUploadIcon"
                                                            >
                                                                <img
                                                                    style={{
                                                                        height: 22,
                                                                    }}
                                                                    src="https://images.ctfassets.net/1itkm9rji8jb/GBEcAOKhA2yzHVXL3RhZQ/f9c2723924c977f8c393e2025672d06d/image-gallery-svgrepo-com.svg"
                                                                    alt=""
                                                                />
                                                                <input
                                                                    type="file"
                                                                    ref={
                                                                        fileInputRef
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            "none",
                                                                    }}
                                                                    onChange={
                                                                        handleFileChange
                                                                    }
                                                                    accept="image/*"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="postContent"
                                                            style={{
                                                                marginBottom: 0,
                                                            }}
                                                        >
                                                            <p
                                                                className="editable"
                                                                onBlur={(e) => {
                                                                    setText(
                                                                        e
                                                                            .currentTarget
                                                                            .textContent
                                                                    );
                                                                }}
                                                                data-placeholder="what's on your mind?"
                                                                contentEditable
                                                                // aria-multiline
                                                                style={{
                                                                    maxHeight: 70,
                                                                    padding: 10,
                                                                    overflowY:
                                                                        "scroll",
                                                                    color: "#fff",
                                                                }}
                                                            >
                                                                {text}
                                                            </p>
                                                            <div className="postedImage">
                                                                {selectedImg && (
                                                                    <img
                                                                        style={{
                                                                            height: 200,
                                                                        }}
                                                                        src={
                                                                            selectedImg
                                                                        }
                                                                        // src="https://images.ctfassets.net/1itkm9rji8jb/7aosR4rBhaGcquUCrya7xJ/6f1327b226d7eec22d47b5e8906a0a05/DCU1K79VLM.jpg"
                                                                        alt=""
                                                                    />
                                                                )}
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    addPost(
                                                                        close
                                                                    );
                                                                }}
                                                                className="postBtn"
                                                            >
                                                                <p className="hasnewbtn">
                                                                    Post
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>

                                        {loader ? (
                                            <div className="mindUploadPost hasboxbg">
                                                <div className="uoloadtextmain">
                                                    <div className="uoloadtext">
                                                        <div className="profileimg">
                                                            <Skeleton
                                                                baseColor="#716e6e"
                                                                circle
                                                                height={50}
                                                                width={50}
                                                            />
                                                        </div>
                                                        <div className="profileText">
                                                            <Skeleton
                                                                baseColor="#716e6e"
                                                                height={10}
                                                                width={200}
                                                                style={{
                                                                    marginTop: 10,
                                                                }}
                                                            />
                                                            <Skeleton
                                                                baseColor="#716e6e"
                                                                height={10}
                                                                width={150}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="postContent">
                                                    <Skeleton
                                                        baseColor="#716e6e"
                                                        height={10}
                                                        width={200}
                                                        style={{
                                                            marginTop: 10,
                                                        }}
                                                    />
                                                    <Skeleton
                                                        baseColor="#716e6e"
                                                        height={10}
                                                        width={300}
                                                    />
                                                    <Skeleton
                                                        baseColor="#716e6e"
                                                        height={10}
                                                        width={400}
                                                    />
                                                    <div
                                                        className="d-flex align-items-center justify-content-center"
                                                        style={{
                                                            overflow: "hidden",
                                                            borderRadius: 10,
                                                        }}
                                                    >
                                                        <Skeleton
                                                            baseColor="#716e6e"
                                                            height={300}
                                                            width={1000}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            userData?.posts &&
                                            userData?.posts.map((item) => {
                                                return (
                                                    <div
                                                        key={item.id}
                                                        className="mindUploadPost hasboxbg"
                                                    >
                                                        <div className="uoloadtextmain">
                                                            <div className="uoloadtext">
                                                                <div className="profileimg">
                                                                    <img
                                                                        src={`${hostname}/${removeSlash(
                                                                            item?.profile_pic
                                                                        )}`}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="profileText">
                                                                    <h4 style={{lineHeight:1.2}} >
                                                                        {
                                                                            item.username
                                                                        }
                                                                    </h4>
                                                                </div>
                                                                <span className="tag">
                                                                    {
                                                                        item.post_type
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="comenttag">
                                                                <span className="time">
                                                                    {item?.updated_at !==
                                                                        null &&
                                                                        "edited "}
                                                                    {moment(
                                                                        item.created_at
                                                                    ).format(
                                                                        "hh:mm A"
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <a
                                                            onClick={() => {
                                                                navigate(
                                                                    "/post",
                                                                    {
                                                                        state: item.id,
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <div className="postContent">
                                                                <p className="mb-2">
                                                                    {
                                                                        item.post_content
                                                                    }
                                                                </p>

                                                                {item.post_img && (
                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                        <img
                                                                            style={{
                                                                                height: "auto",
                                                                                maxWidth:
                                                                                    "100%",
                                                                            }}
                                                                            src={`${hostname}/${removeSlash(
                                                                                item.post_img
                                                                            )}`}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </a>
                                                        <RenderLikeBar
                                                            item={item}
                                                        />
                                                        {/* <LikeBar
                                                            otherUserProfile={
                                                                true
                                                            }
                                                            postData={item}
                                                            edit={false}
                                                        /> */}
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
