import React, { useState, useEffect, useRef, useContext } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { FileText, Play, Volume2 } from "lucide-react";
import { postRequest } from "../../../utills/requests";
import {
    setEventsData,
    setStyling,
    submitRequest,
} from "../../../store/slices/bodyStyling";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { removeStartingSlash } from "../../../utills/RemoveSlash";
import { DarkModeContext } from "../../../context/DarkModeContext";
import moment from "moment";

const TeamDetailWidget = ({ itemStyles, height, width, widgetId }) => {
    const [border, setBorder] = useState("");
    const [defaultCorners, setDefaultCorners] = useState("true");
    const user = useSelector((state) => state.login.userData);
    const universalStyles = useSelector(
        (state) => state.bodyStyling.universalStyles
    );
    const widgets = useSelector((state) => state.bodyStyling.styling);
    const eventsData = useSelector((state) => state.bodyStyling.eventsData);
    const teamId = useSelector((state) => state.bodyStyling.teamId);
    const dispatch = useDispatch();
    const [selectedEvent, setSelectedEvent] = useState({});

    const [events, setEvents] = useState([]);
    const [openSongId, setOpenSongId] = useState("");
    const [selectedOption, setOption] = useState(1);

    const { darkMode } = useContext(DarkModeContext);

    useEffect(() => {
        if (
            eventsData &&
            eventsData?.events !== "Not in a team" &&
            eventsData?.events?.length > 0
        ) {
            const filteredEvents = eventsData?.events.filter(
                (item) => item.status !== 2
            );
            setEvents(filteredEvents);
            const event = JSON.parse(JSON.stringify(filteredEvents[0]));
            const sortedSongs = sortSongsByOrder(event.event_songs);

            const updatedData = getArramgements(sortedSongs);

            const data = { ...event, event_songs: updatedData };
            // console.log("DATA>", data);

            setSelectedEvent(data);
        } else {
            setEvents([]);
            setSelectedEvent({});
        }
    }, [eventsData]);

    const sortSongsByOrder = (songs) => {
        try {
            if (songs && songs?.length > 0) {
                const res = songs.sort(
                    (a, b) => a.order_status - b.order_status
                );
                return res;
            }
        } catch (error) {
            return [];
        }
    };

    const getArramgements = (songs) => {
        const newData =
            songs &&
            songs.map((item) => {
                const song = eventsData?.songs.find(
                    (song) => song.id === item.song_id
                );
                if (!song || !song.song_details?.arrangements) {
                    return item;
                }
                const arrangement = song.song_details.arrangements.find(
                    (arrangement) => arrangement.id === item?.active_arrangement
                );
                return arrangement ? { ...item, arrangement } : item;
            });
        // console.log("NEW>", newData);
        return newData;
    };

    useEffect(() => {
        if (teamId) {
            getTeamData(teamId);
        }
    }, [teamId]);

    useEffect(() => {
        if (universalStyles.corners && defaultCorners == "true") {
            setBorder(universalStyles.corners);
        }
    }, [universalStyles.corners, universalStyles.font]);

    const getTeamData = async (team_id) => {
        const data = {
            user_id: user.id,
            team_id,
        };
        dispatch(submitRequest(true));
        try {
            const response = await postRequest({
                endpoint: "/myTeam",
                payload: data,
            });
            if (response?.data) {
                dispatch(setEventsData(response.data));
                if (response.data?.members) {
                    arrangeMembersWidget(response.data?.members);
                }
            }
            // console.log("RESPONSE>", response.data);
        } catch (error) {
            console.log("ERR>", error);
        } finally {
            dispatch(submitRequest(false));
        }
    };

    const arrangeMembersWidget = (data) => {
        const memberWidget = widgets?.homepageOrder?.find(
            (item) => item.widgetInfo?.name === "team-member"
        );

        if (memberWidget !== undefined) {
            const newOrder = widgets?.homepageOrder.filter(
                (item) => item.widgetInfo?.name !== "team-member"
            );

            data.forEach((element, index) => {
                newOrder.push({
                    ...memberWidget,
                    widget_id: uuidv4(),
                    member: element,
                    x: (index * memberWidget.w) % 6,
                });
            });

            const updatedData = { ...data, homepageOrder: newOrder };
            dispatch(setStyling(updatedData));
        }
    };

    const handleInvitation = async (invitation_id, status) => {
        const data = {
            invitation_id,
            status,
        };
        dispatch(submitRequest(true));
        try {
            const response = await postRequest({
                endpoint: "/invitationResponse",
                payload: data,
            });
            if (response.data.status === "success") {
                if (status == 1) {
                    setEvents((prev) =>
                        prev.map((item) =>
                            item.invitation_id == invitation_id
                                ? { ...item, status: 1 }
                                : item
                        )
                    );
                    toast.success("Invitation Accepted.", { duration: 2500 });
                } else {
                    setEvents((prev) =>
                        prev.filter(
                            (item) => item.invitation_id !== invitation_id
                        )
                    );
                    toast.success("Invitation Declined.", { duration: 2500 });
                }
            }
            console.log("RESPONSE>", response.data);
        } catch (error) {
            console.log("ERR>", error);
        } finally {
            dispatch(submitRequest(false));
        }
    };

    const options = [
        { id: 1, name: "Info" },
        { id: 2, name: "Arrangements" },
        { id: 3, name: "File" },
        { id: 4, name: "Notes" },
    ];

    const getWorshipEventTimes = (events) => {
        // Step 1: Filter events with `deleted_at === null` and `type === 'worship'`
        const worshipEvents = events.filter(
            (event) => event.deleted_at === null && event.type === "worship"
        );

        // Step 2: Check if there are valid worship events
        if (!worshipEvents.length) return "No valid worship events";

        // Step 3: Extract `event_time` values and sort them properly
        const sortedTimes = worshipEvents
            .map((event) => ({
                ...event,
                parsedTime: new Date(
                    `1970-01-01T${convertTo24Hour(event.event_time)}`
                ),
            }))
            .sort((a, b) => a.parsedTime - b.parsedTime);

        // Step 4: Get the earliest and latest event times
        const earliestTime = sortedTimes[0].event_time;
        const latestTime = sortedTimes[sortedTimes.length - 1].event_time;

        return `${earliestTime} - ${latestTime}`;
    };

    // Helper function to convert AM/PM to 24-hour format
    const convertTo24Hour = (time) => {
        const [hoursMinutes, modifier] = time.split(" ");
        let [hours, minutes] = hoursMinutes.split(":");

        if (modifier === "PM" && hours !== "12") {
            hours = String(parseInt(hours, 10) + 12);
        } else if (modifier === "AM" && hours === "12") {
            hours = "00";
        }

        return `${hours}:${minutes}`;
    };

    return (
        <div
            className={`d-flex team-detail flex-wrap`}
            style={{
                borderRadius:
                    border === "round" ? 20 : border === "sharp" ? 0 : null,
            }}
        >
            {events.length == 0 ? (
                <div
                    className="d-flex"
                    style={{
                        padding: 20,
                        width: "100%",
                        backgroundColor: "#171717",
                    }}
                >
                    <h4 style={{ color: "#fff" }}>No Events Found</h4>
                </div>
            ) : (
                <>
                    <div className="d-flex col-md-8 event-detail-cont">
                        <div className="d-flex row row-gap-2 ">
                            {/* img */}
                            <div className="col-md-4">
                                <img
                                    src={
                                        selectedEvent?.banner
                                            ? `${process.env.REACT_APP_BASE_URL}${selectedEvent?.banner}`
                                            : Assets.Event1
                                    }
                                    alt="event-img"
                                />
                            </div>
                            {/* details */}
                            <div className="d-flex col-md-8 flex-column gap-2">
                                <h6 className="title-text">
                                    {selectedEvent?.event_name}
                                </h6>
                                <p className="sub-text">
                                    {selectedEvent?.event_desc}
                                </p>
                                <p className="sub-text">
                                    {selectedEvent?.event_date}
                                </p>
                            </div>
                        </div>
                        {/* songs */}
                        <div className="d-flex flex-column gap-2 mt-4 track-cont ">
                            {selectedEvent?.event_songs &&
                                selectedEvent.event_songs.length > 0 &&
                                selectedEvent.event_songs.map((item) => {
                                    const { song_details } =
                                        item?.event_song_data;
                                    return item.header_text == null ? (
                                        <div
                                            key={item.id}
                                            className=" d-flex flex-column align-items-start"
                                        >
                                            <div
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        setOption(1);
                                                    }, 100);

                                                    setOpenSongId((prev) =>
                                                        prev == item.id
                                                            ? ""
                                                            : item.id
                                                    );
                                                }}
                                                className="song-cont d-flex align-items-center"
                                                style={{ width: "100%" }}
                                            >
                                                <Play
                                                    fill="#343434"
                                                    color="#343434"
                                                    size={26}
                                                    style={{
                                                        cursor: "pointer",
                                                        transform:
                                                            openSongId ==
                                                            item.id
                                                                ? "rotate(0.25turn)"
                                                                : "rotate(1turn)",
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        color: "#fff",
                                                        textAlign: "center",
                                                        minWidth: 40,
                                                    }}
                                                >
                                                    {item?.arrangement?.length
                                                        ? item.arrangement
                                                              .length
                                                        : "0:0"}
                                                </span>
                                                <span
                                                    style={{
                                                        fontSize: 14,
                                                        margin: 0,
                                                        marginRight: 30,
                                                    }}
                                                >
                                                    {song_details?.song_name}
                                                    {item?.arrangement
                                                        ?.title ? (
                                                        <span
                                                            style={{
                                                                fontStyle:
                                                                    "italic",
                                                                margin: 0,
                                                                opacity: 0.8,
                                                            }}
                                                        >
                                                            (
                                                            {
                                                                item
                                                                    ?.arrangement
                                                                    ?.title
                                                            }
                                                            )
                                                            {item?.arrangement
                                                                ?.keys?.[0]?.key
                                                                ? `(${item?.arrangement?.keys?.[0]?.key})`
                                                                : ""}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>

                                                {item?.keyFiles?.[0]?.path && (
                                                    <a
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        href={`${process.env.REACT_APP_BASE_URL}${item?.keyFiles?.[0]?.path}`}
                                                        target="_blank"
                                                    >
                                                        <img
                                                            src={Assets.Pdf}
                                                            style={{
                                                                width: 20,
                                                                height: 20,
                                                                borderRadius: 0,
                                                                marginInline: 10,
                                                                filter: `brightness(${
                                                                    darkMode
                                                                        ? "1"
                                                                        : "0"
                                                                })`,
                                                            }}
                                                        />
                                                    </a>
                                                )}

                                                {song_details?.song_mp3 && (
                                                    <a
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        href={`${process.env.REACT_APP_BASE_URL}${song_details.song_mp3}`}
                                                        target="_blank"
                                                    >
                                                        <img
                                                            src={Assets.Speaker}
                                                            style={{
                                                                width: 20,
                                                                height: 20,
                                                                filter: `brightness(${
                                                                    darkMode
                                                                        ? "1"
                                                                        : "0"
                                                                })`,
                                                            }}
                                                        />
                                                    </a>
                                                )}
                                            </div>
                                            {/* dropdown */}
                                            <div
                                                className={`song-info-content d-flex flex-column gap-3 ${
                                                    openSongId == item.id
                                                        ? "open"
                                                        : ""
                                                }`}
                                                style={{
                                                    backgroundColor: "#0e0e0e",
                                                    width: "100%",
                                                }}
                                            >
                                                <div className="d-flex gap-2 option-parent">
                                                    {options.map((item) => {
                                                        return (
                                                            <div
                                                                key={item.id}
                                                                className={`option-cont ${
                                                                    item.id ==
                                                                    selectedOption
                                                                        ? "selected"
                                                                        : ""
                                                                } `}
                                                                onClick={() => {
                                                                    setOption(
                                                                        item.id
                                                                    );
                                                                }}
                                                            >
                                                                <span>
                                                                    {item.name}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                {/* Info */}
                                                {selectedOption == 1 ? (
                                                    <div className="d-flex gap-2 align-items-center">
                                                        <div
                                                            style={{
                                                                width: "20%",
                                                            }}
                                                        >
                                                            <img
                                                                style={{
                                                                    height: 80,
                                                                    borderRadius: 10,
                                                                }}
                                                                src={
                                                                    song_details?.song_banner
                                                                        ? `${
                                                                              process
                                                                                  .env
                                                                                  .REACT_APP_BASE_URL
                                                                          }/${removeStartingSlash(
                                                                              song_details?.song_banner
                                                                          )}`
                                                                        : Assets.Event1
                                                                }
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column gap-2">
                                                            <h6
                                                                style={{
                                                                    color: "#fff",
                                                                }}
                                                            >
                                                                {
                                                                    song_details?.song_name
                                                                }
                                                            </h6>
                                                            <h6
                                                                style={{
                                                                    color: "#fff",
                                                                }}
                                                            >
                                                                {
                                                                    song_details?.artist
                                                                }
                                                            </h6>
                                                            <div className="youtube-cont">
                                                                <Play
                                                                    fill="#343434"
                                                                    color="#343434"
                                                                    size={20}
                                                                />
                                                                <h6>Youtube</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : // Arrangements
                                                selectedOption == 2 ? (
                                                    <div className="d-flex flex-wrap gap-2 justify-content-between ">
                                                        <div className="arrangement-option col-md-3">
                                                            <h6>
                                                                {
                                                                    item
                                                                        ?.arrangement
                                                                        ?.title
                                                                }
                                                            </h6>
                                                        </div>
                                                        <div className="arrangement-option col-md-3">
                                                            <h6>
                                                                {
                                                                    item
                                                                        ?.arrangement
                                                                        ?.keys?.[0]
                                                                        ?.key
                                                                }
                                                            </h6>
                                                        </div>
                                                        <div className="arrangement-option col-md-3">
                                                            <h6>
                                                                {
                                                                    item
                                                                        ?.arrangement
                                                                        ?.length
                                                                }
                                                            </h6>
                                                        </div>
                                                    </div>
                                                ) : // File
                                                selectedOption == 3 ? (
                                                    <div className="d-flex flex-column gap-3">
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-flex col-md-2">
                                                                <h6
                                                                    style={{
                                                                        color: "#bababa",
                                                                        fontSize: 14,
                                                                    }}
                                                                >
                                                                    Key
                                                                </h6>
                                                            </div>
                                                            <div className="d-flex col-md-10">
                                                                <h6
                                                                    style={{
                                                                        color: "#bababa",
                                                                        fontSize: 14,
                                                                    }}
                                                                >
                                                                    File
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-flex col-md-2">
                                                                <h6
                                                                    style={{
                                                                        color: "#bababa",
                                                                        fontSize: 14,
                                                                    }}
                                                                >
                                                                    {
                                                                        item
                                                                            ?.arrangement
                                                                            ?.keys?.[0]
                                                                            ?.key
                                                                    }
                                                                </h6>
                                                            </div>
                                                            <a
                                                                href={`${item?.keyFiles?.[0]?.path}`}
                                                                target="_blank"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <div className="d-flex col-md-10 align-items-center gap-2">
                                                                    {item
                                                                        ?.keyFiles?.[0]
                                                                        ?.path && (
                                                                        <>
                                                                            <img
                                                                                src={
                                                                                    Assets.PdfBlu
                                                                                }
                                                                                style={{
                                                                                    height: 20,
                                                                                    width: 20,
                                                                                    borderRadius: 0,
                                                                                }}
                                                                            />
                                                                            <h6
                                                                                style={{
                                                                                    color: "#007bff",
                                                                                    fontSize: 14,
                                                                                }}
                                                                            >
                                                                                {item?.keyFiles?.[0]?.path
                                                                                    ?.split(
                                                                                        "/"
                                                                                    )
                                                                                    .pop() ||
                                                                                    ""}
                                                                            </h6>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex flex-column gap-3">
                                                        <div className="d-flex align-items-center">
                                                            <div className="col-md-3">
                                                                <h6
                                                                    style={{
                                                                        color: "#bababa",
                                                                        fontSize: 14,
                                                                    }}
                                                                >
                                                                    Vocal Notes:
                                                                </h6>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <h6
                                                                    style={{
                                                                        color: "#bababa",
                                                                        fontSize: 14,
                                                                    }}
                                                                >
                                                                    {
                                                                        item.vocal_notes
                                                                    }
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="col-md-3">
                                                                <h6
                                                                    style={{
                                                                        color: "#bababa",
                                                                        fontSize: 14,
                                                                    }}
                                                                >
                                                                    Band Notes:
                                                                </h6>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <h6
                                                                    style={{
                                                                        color: "#bababa",
                                                                        fontSize: 14,
                                                                    }}
                                                                >
                                                                    {
                                                                        item.band_notes
                                                                    }
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="col-md-3">
                                                                <h6
                                                                    style={{
                                                                        color: "#bababa",
                                                                        fontSize: 14,
                                                                    }}
                                                                >
                                                                    Production
                                                                    Notes:
                                                                </h6>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <h6
                                                                    style={{
                                                                        color: "#bababa",
                                                                        fontSize: 14,
                                                                    }}
                                                                >
                                                                    {
                                                                        item.production_notes
                                                                    }
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="song-cont d-flex"
                                            style={{
                                                backgroundColor: "#000",
                                                cursor: "default",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "#a5a5a5",
                                                    marginLeft: 15,
                                                }}
                                            >
                                                {item.header_text}
                                            </span>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div
                        className="team-right-col d-flex col-md-4 flex-column gap-3"
                        style={{
                            overflowY: "scroll",
                            paddingLeft: 10,
                            overflowX: "hidden",
                            height: "100%",
                        }}
                    >
                        {events.map((item, index) => {
                            return item.status == 1 ? (
                                <div
                                    key={index}
                                    onClick={() => {
                                        const temp = JSON.parse(
                                            JSON.stringify(item)
                                        );
                                        const sortedSongs = sortSongsByOrder(
                                            temp.event_songs
                                        );
                                        const updatedData =
                                            getArramgements(sortedSongs);
                                        const data = {
                                            ...temp,
                                            event_songs: updatedData,
                                        };
                                        setSelectedEvent(data);
                                    }}
                                    className={`event-item-cont ${
                                        selectedEvent.invitation_id ==
                                        item.invitation_id
                                            ? "selected-event"
                                            : "unselected-event"
                                    }`}
                                >
                                    <div style={{ position: "relative" }}>
                                        <img
                                            className="event-img"
                                            src={
                                                item.banner
                                                    ? `${process.env.REACT_APP_BASE_URL}${item.banner}`
                                                    : Assets.Event1
                                            }
                                        />
                                        <div className="imageInnerText">
                                            <h4>{item.event_name}</h4>
                                            <h4 style={{color: "#a5a5a5"}} >{item?.position_name}</h4>
                                        </div>
                                    </div>
                                </div>
                            ) : item.status == 0 ? (
                                <div
                                    className={` event-item-cont ${
                                        selectedEvent.invitation_id ==
                                        item.invitation_id
                                            ? "selected-event"
                                            : "unselected-event"
                                    } d-flex flex-column align-items-center justify-content-center`}
                                    onClick={() => {
                                        const temp = JSON.parse(
                                            JSON.stringify(item)
                                        );
                                        const sortedSongs = sortSongsByOrder(
                                            temp.event_songs
                                        );
                                        const updatedData =
                                            getArramgements(sortedSongs);
                                        const data = {
                                            ...temp,
                                            event_songs: updatedData,
                                        };
                                        setSelectedEvent(data);
                                    }}
                                >
                                    <p className="event-name">
                                        {item.event_name}
                                    </p>
                                    <p style={{ color: "#a5a5a5" }}>
                                        {moment(item?.event_date).format(
                                            "DD-MM-YYYY"
                                        )}{" "}
                                        |{" "}
                                        {getWorshipEventTimes(
                                            item?.time_details
                                        )}
                                    </p>
                                    <p style={{ color: "#a5a5a5", margin: 0 }}>
                                        {item?.position_name}
                                    </p>
                                    <div className="d-flex align-items-center justify-content-center gap-3 mt-2">
                                        <button
                                            onClick={(e) => {
                                                handleInvitation(
                                                    item.invitation_id,
                                                    1
                                                );
                                                e.stopPropagation();
                                            }}
                                            className="accept-btn"
                                        >
                                            Accept
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                handleInvitation(
                                                    item.invitation_id,
                                                    2
                                                );
                                                e.stopPropagation();
                                            }}
                                            className="decline-btn"
                                        >
                                            Decline
                                        </button>
                                    </div>
                                </div>
                            ) : null;
                        })}
                    </div>
                </>
            )}
        </div>
    );
};

export default TeamDetailWidget;
