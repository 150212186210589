import React from "react";
import placeholder from "../../assets/placeholder.png";
import likeIcon from "../../assets/images/heartNoti.png";
import commentIcon from "../../assets/images/commentNoti.png";
import requestIcon from "../../assets/images/requestNoti.png";
import invitationIcon from "../../assets/images/invitationNoti.png";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";

const NotificationItem = ({
    item,
    handleEvent,
    handleRequest,
    loader,
    changeNotificationStatus,
    hideNotificationMenu,
    darkMode,
}) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const color = {
        unread:
            pathname == "/notifications" && darkMode
                ? "#252525"
                : darkMode
                ? "#1b1b1b"
                : "#fff",
        read: !darkMode ? "#02020233" : "rgba(255,255,255,0.2)",
    };

    const ButtonsComp = ({ item, loader, handleEvent, handleRequest }) => {
        const btnStyles = {
            borderRadius: 4,
            paddingInline: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: 82,
            color: "#fff",
        };

        return (
            <div className="d-flex gap-2 mt-2">
                <button
                    disabled={loader.accept || loader.decline}
                    onClick={(e) => {
                        item?.type == "1"
                            ? handleRequest(
                                  item?.resource_id,
                                  "accept",
                                  item?.id
                              )
                            : handleEvent(
                                  item?.event_detail?.invitation_id,
                                  1,
                                  item?.id
                              );
                        e.stopPropagation();
                    }}
                    style={{ ...btnStyles, backgroundColor: "#28a830" }}
                >
                    {loader.notificationId == item?.id && loader.accept ? (
                        <Circles
                            height="16"
                            width="16"
                            color="#fff"
                            ariaLabel="circles-loading"
                            wrapperClass=""
                            visible={true}
                        />
                    ) : (
                        "Accept"
                    )}
                </button>
                <button
                    disabled={loader.accept || loader.decline}
                    onClick={() => {
                        item?.type == "1"
                            ? handleRequest(
                                  item?.resource_id,
                                  "decline",
                                  item?.id
                              )
                            : handleEvent(
                                  item?.event_detail?.invitation_id,
                                  2,
                                  item?.id
                              );
                    }}
                    style={{ ...btnStyles, backgroundColor: "#ff3434" }}
                >
                    {loader.notificationId == item?.id && loader.decline ? (
                        <Circles
                            height="16"
                            width="16"
                            color="#fff"
                            ariaLabel="circles-loading"
                            wrapperClass=""
                            visible={true}
                        />
                    ) : (
                        "Decline"
                    )}
                </button>
            </div>
        );
    };

    return (
        <div
            className="d-flex gap-3 noti-item "
            style={{
                borderRadius: 4,
                backgroundColor: item?.is_read == 0 ? color.read : color.unread,
                padding: 10,
            }}
            onClick={() => {
                item?.is_read == 0 &&
                    changeNotificationStatus([item?.id], "individual");
                if (item?.type == 2 || item?.type == 1 || item?.type == 4) {
                    navigate("/otherUserProfile", {
                        state: item?.sender_id,
                    });
                } else if (item?.type == 5) {
                    navigate("/post", {
                        state: item?.post_detail?.post_id,
                    });
                } else if (item?.type == 6 || item?.type == 7) {
                    navigate("/post", {
                        state: item?.post_id,
                    });
                }

                if (
                    item?.type == 1 ||
                    item?.type == 2 ||
                    item?.type == 4 ||
                    item?.type == 5 ||
                    item?.type == 6 ||
                    item?.type == 7
                ) {
                    hideNotificationMenu && hideNotificationMenu();
                }
            }}
        >
            <div style={{ position: "relative", maxHeight: 56 }}>
                <img
                    className="noti-profile-img"
                    src={
                        item?.sender?.profile_pic
                            ? `${process.env.REACT_APP_BASE_URL}/${item?.sender?.profile_pic}`
                            : placeholder
                    }
                />
                <img
                    className="noti-icon-img"
                    src={
                        item?.type == "5" || item?.type == "7"
                            ? likeIcon
                            : item?.type == "6"
                            ? commentIcon
                            : item?.type == "3" || item.type == "4"
                            ? invitationIcon
                            : item?.type == "1" || item.type == "2"
                            ? requestIcon
                            : null
                    }
                />
            </div>
            <div className="d-flex flex-column gap-2 justify-content-between">
                <div className="d-flex flex-column">
                    {/* <p style={{ color: "#fff" }}> {item?.sender?.username} </p> */}
                    <p
                        className="three-line-ellipsis"
                        style={{
                            color: !darkMode ? "#000" : "#fff",
                            margin: 0,
                            fontSize: pathname !== "/notifications" ? 12 : 16,
                        }}
                    >
                        {item?.type == 2 || item?.type == 4
                            ? `${item?.sender?.username} has accepted your ${
                                  item?.type == 2 ? "connection" : "invitation"
                              } request.`
                            : ""}
                        {item?.type == 1 && item?.status == 0
                            ? `${item?.sender?.username} has sent a connection request.`
                            : item?.type == 1 && item?.status == 1
                            ? `You accepted ${item?.sender?.username}'s connection request.`
                            : item?.type == 1 && item?.status == 2
                            ? `You declined ${item?.sender?.username}'s connection request.`
                            : ""}
                        {item?.type == 3 &&
                        item?.event_detail?.invitation_status == 0
                            ? `${item?.sender?.username} has invited you for the position of ${item?.event_detail?.position_name}.`
                            : item?.type == 3 &&
                              item?.event_detail?.invitation_status == 1
                            ? `You accepted ${item?.sender?.username}'s invitation for the position of ${item?.event_detail?.position_name}.`
                            : item?.type == 3 &&
                              item?.event_detail?.invitation_status == 2
                            ? `You declined ${item?.sender?.username}'s invitation for the position of ${item?.event_detail?.position_name}.`
                            : ""}
                        {item?.type == 5
                            ? `${item?.sender?.username} liked your post.`
                            : item?.type == 6
                            ? `${item?.sender?.username} commented on your post.`
                            : item?.type == 7
                            ? `${item?.sender?.username} liked your comment.`
                            : ""}
                    </p>
                </div>
                <div>
                    <p style={{ color: "grey", fontSize: 10 }}>
                        {moment(item.created_at).fromNow()}
                    </p>
                    {((item?.type == "3" &&
                        item?.event_detail?.invitation_status == 0) ||
                        item?.status == 0) && (
                        <ButtonsComp
                            item={item}
                            loader={loader}
                            handleEvent={handleEvent}
                            handleRequest={handleRequest}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default NotificationItem;
